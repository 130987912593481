//"use strict";

import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import pg_logo_mini from 'img/pg_logo_mini_alpha.png';

const styles = theme => {
    return {
        root: {
            display: "flex",
            position: "absolute",
            border: "1px solid white",
            borderRadius: 20,
            backgroundColor: "#e2e1e0",
            zIndex: 1000,
            top: 5,
            left: 5,
            paddingRight: 10,
            lineHeight: "25px",
            flexDirection: "row",
            alignItems: "center",
            opacity: 0.8,
        },
        titleless: {
            position: "absolute",
            top: 5,
            left: 5,
            opacity: 0.8,
            zIndex: 1000,
            backgroundColor: "#e2e1e0",
            borderRadius: "50%",
        },
        menuElement: {
            display: "inline-block"
        },
        logo: {
            height: 35,
            marginTop: 3
        }
    }
};

class TitleBar extends React.Component {
    menuClicked() {
        const { onMenuClick } = this.props;
        if (onMenuClick) onMenuClick()
    }

    render() {
        const { classes, visible } = this.props;
        if (!visible) return null;

        if (this.props.hidetitle) {
            return (
                <div className={classes.titleless}>
                    <IconButton color="inherit" aria-label="Menu" onClick={() => this.menuClicked()}>
                        <MenuIcon />
                    </IconButton>
                </div>
            )
        }
        return (
            <div className={classes.root}>
                <div className={classes.menuElement}>
                    <IconButton color="inherit" aria-label="Menu" onClick={() => this.menuClicked()}>
                        <MenuIcon />
                    </IconButton>
                </div>
                <div className={classes.menuElement}>{this.props.children}</div>
                <div><a href="https://www.protegear.de" target="_blank" title="Open Protegear"><img className={classes.logo} src={pg_logo_mini} /></a></div>
            </div>
        )
    }
}

export default withStyles(styles)(TitleBar);


