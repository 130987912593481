import Iterator from 'collections/iterator';
import SortedSet from 'collections/sorted-set';
//import * as SS from 'collections/sorted-set';

import * as api from './api/api';


import { validPosition } from 'gstjs2/backend';

function eventEquals(e1, e2) {
    let s1 = new Date(e1.sent).getTime();
    let s2 = new Date(e2.sent).getTime();
    return s1 == s2;
}

function eventCompare(e1, e2) {
    let s1 = new Date(e1.sent).getTime();
    let s2 = new Date(e2.sent).getTime();
    return s1 - s2;
}

function promiseAllStepN(n, list) {
    let tail = list.splice(n)
    let head = list
    let resolved = []
    let processed = 0
    return new Promise(resolve => {
        head.forEach(x => {
            let res = x()
            resolved.push(res)
            res.then(y => {
                runNext()
                return y
            })
        })
        function runNext() {
            if (processed == tail.length) {
                resolve(Promise.all(resolved))
            } else {
                resolved.push(tail[processed]().then(x => {
                    runNext()
                    return x
                }))
                processed++
            }
        }
    })
}

let allConcurrent = n => list => promiseAllStepN(n, list)

export class DeviceTrack {
    constructor(trip, dev, info, track, tail) {
        this.events = new SortedSet([], eventEquals, eventCompare);
        this.device = dev;
        this.trip = trip;
        this.statistic = null;
        this.info = info;
        this.visible = true;
        this.useTail = tail;
        this.setTracking(track);
        //this._fetchEvents()

    }

    getImei() {
        return this.device.id;
    }

    setVisible(v) {
        this.visible = v;
    }

    setInfo(i) {
        this.info = i;
    }

    getID() {
        return this.statistic.latestEvent.sent.getTime();
    }

    addStatistic(s) {
        this.statistic = s;
        if (s.latestEvent) {
            s.latestEvent.sent = new Date(s.latestEvent.sent);
            this.addEvent(s.latestEvent);
        }
    }

    addEvent(evt) {
        if (evt.position && validPosition(evt.position)) {
            if (!this.track) this.events.clear();
            this.events.push(evt);
        }
    }
    addEvents(evts) {
        for (let e of evts) {
            this.addEvent(e)
        }
    }

    getSlice(from, to) {
        let res = [];
        new Iterator(this.events.iterate({ sent: from }, { sent: to })).forEach(e => {
            res.push(e);
        })
        return res;

    }

    getAt(ts) {
        let res = this.events.findGreatestLessThanOrEqual({ sent: ts });
        if (res) return res.value;
        return null;
    }

    getNext(ts) {
        let res = this.events.findLeastGreaterThanOrEqual({ sent: ts });
        if (res) return res.value;
        return null;
    }

    getLeast() {
        let v = this.events.findLeast();
        return v ? v.value : null;
    }

    getGreatest() {
        let v = this.events.findGreatest();
        return v ? v.value : null;
    }

    hasPosition() {
        if (this.statistic) {
            let lp = this.statistic.latestPosition;
            return lp && lp.lat != 0 && lp.lng != 0;
        }
        return false
    }

    getCurrentPosition() {
        return this.statistic.latestPosition;
    }

    getCurrentPositionLatLng() {
        return new google.maps.LatLng(this.getCurrentPosition());
    }

    setTail(b) {
        this.useTail = b
    }

    getTail() {
        return this.useTail;
    }

    // __setTracking(t, cb) {
    //     this.track = t;
    //     if (t) {
    //         //if (this.events.length < 1) {
    //         allConcurrent(1)([() => this._fetchEvents(cb)]);
    //         //}
    //     }
    // }
    setTracking(t, cb) {
        this.track = t;
        if (t) {
            api.addWorkerJob(this.device.id, (evts) => {
                evts = evts.map(e => {
                    e.event.sent = new Date(e.sent);
                    return e.event;
                });
                this.addEvents(evts);
                if (cb) cb(this);
            })
        } else {
            api.clearWorkerJobs(this.device.id);
        }
    }

    // _fetchEvents(cb) {
    //     let limit = 0;
    //     let skip = 0;

    //     let fetcher = () => {
    //         return fetch(`/tripview/api/v1/trip/${this.trip.id}/${this.device.id}/events?limit=${limit}&skip=${skip}`, api.fetchInit("GET"))
    //             .then(api.check)
    //             .then(evts => {
    //                 evts = evts.map(e => {
    //                     e.event.sent = new Date(e.sent);
    //                     return e.event;
    //                 });
    //                 this.addEvents(evts);
    //                 if (cb) cb(this);
    //                 /*if (evts.length >= limit) {
    //                     skip += evts.length;
    //                     fetcher()
    //                 }*/
    //             });
    //     }
    //     fetcher = fetcher.bind(this)
    //     return fetcher();
    // }

}

export class DeviceTracks {
    constructor(trip, devs, statistics, info, tracking, tailing, cb) {
        this._info = info
        this._tracking = tracking;
        this._tailing = tailing
        this.tracks = {};
        this.update(trip, devs, statistics)
    }

    update(trip, devs, statistics) {
        this.trip = trip;
        this.latestTS = new Date();
        devs = devs || [];
        this.tracks = (devs || []).reduce((p, c) => {
            if (!p[c.id]) {
                if (c.id) {
                    p[c.id] = new DeviceTrack(trip, c, this._info[c.id], this._tracking[c.id], this._tailing[c.id]);
                }
            }
            return p;
        }, this.tracks);
        const devmap = (devs || []).reduce((p, c) => ({ ...p, [c.id]: c }), {});
        for (const o of Object.keys(this.tracks)) {
            if (!devmap[o]) {
                delete (this.tracks, o)
            }
        }
        this._fill(statistics || {});
    }


    _fill(statistics) {
        for (let [imei, s] of Object.entries(statistics)) {
            if (imei) // this happens, when an empty statistic is in the map
                this.addStatistic(s)
        }
    }

    addStatistic(s) {
        let track = this?.tracks?.[s.imei];
        if (track) track.addStatistic(s);
        if (s.latestEvent) {
            let ts = new Date(s.latestEvent.sent);
            if (ts.getTime() > this.latestTS.getTime())
                this.latestTS = new Date(ts.getTime() + 1000); // the latest TS must be greater than the latest event
        }
    }

    getTrack(imei) {
        return this.tracks[imei];
    }

    getTracks() {
        return this.tracks;
    }

    getCurrentPosition(imei) {
        let track = this.tracks[imei];
        if (track && track.hasPosition()) {
            let pos = track.getCurrentPositionLatLng()
            return Promise.resolve(pos);
        }
        return Promise.reject("no track for imei found");
    }

    allWithPositions() {
        let res = [];
        for (let [imei, track] of Object.entries(this.tracks)) {
            if (track.hasPosition() && track.visible) {
                res.push(track);
            }
        }
        return res;
    }

    getCenterAndBounds() {
        let bounds = new google.maps.LatLngBounds();
        let positions = [];

        for (let [imei, t] of Object.entries(this.tracks)) {
            if (t.hasPosition()) {
                let gp = t.getCurrentPositionLatLng();
                positions.push(gp);
                bounds.extend(gp);
            }
        }
        if (positions.length > 1) {
            return { bounds, center: bounds.getCenter(), zoom: 0 };
        }
        if (positions.length == 1) {
            return { bounds, center: positions[0], zoom: 11 };
        }
        return { bounds, center: null, zoom: 8 };
    }
}
