"use strict";

import MomentUtils from '@date-io/moment';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Anonymous } from 'gstjs2/backend';
import React from 'react';
import { render } from 'react-dom';
import TripviewApp from './TripView';

if (!window.location.origin) { // Some browsers (mainly IE) do not have this property, so we need to build it manually...
    window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
}

// achtung: sehr triviale implementierung, wenn cookies gleich enden geht das nicht!
window.getSimpleCookie = function (name) {
    let match = document.cookie.match(new RegExp(name + '=([^;]+)'));
    if (match) return match[1];
    return null;
}

const theme = createTheme({});

let tripviewapp = (
    <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <TripviewApp />
        </MuiPickersUtilsProvider>
    </MuiThemeProvider>
);

// init the gstjs in anonymous mode
Anonymous();

render(tripviewapp, document.getElementById('main'));
