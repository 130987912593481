//"use strict";

import { css } from '@emotion/react';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkIcon from '@material-ui/icons/Link';
import MailIcon from '@material-ui/icons/Mail';
import TwitterIcon from '@material-ui/icons/Twitter';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { saveAs } from 'file-saver';
import GoogleMapReact from 'google-map-react';
import { getParameter } from 'gstjs2/backend';
import error_occured from 'img/error.svg';
import pg_logo_mini from 'img/pg_logo_mini.png';
import moment from 'moment';
import * as mqtt from 'mqtt/dist/mqtt.min';
import React from 'react';
import GridLoader from 'react-spinners/GridLoader';
import { DeviceTracks } from './DeviceTracks';
import { DisplayedTrack } from './DisplayedTrack';
import ExportDialog from './ExportDialog';
import MenuDeviceList from './MenuDeviceList';
import TimePopper from './TimePopper';
import TimelineControl from './TimelineControl';
import TitleBar from './TitleBar';
import * as api from './api/api';
import { DeviceMarker, colorFromDevice } from './components/DeviceMarker';

const drawerWidth = 270;
const timelineHeight = 120;

//AIzaSyAex5YA57LGWUhFv9wFtMVDDiuEeosMpgE

const mapsKey = "AIzaSyAex5YA57LGWUhFv9wFtMVDDiuEeosMpgE";

const spinner = css`
    display: block;
    margin: 100px auto;
    border-color: red;
`;

const styles = theme => {
    return {
        root: {
            flexGrow: 1,
            height: '100svh',
            width: '100vw',
            position: "absolute",
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        map: {
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            position: "absolute",
        },
        mapWithDrawer: {
            left: drawerWidth,
            width: "calc(100% - " + drawerWidth + "px)",
        },
        mapWithTimeline: {
            height: "calc(100svh - " + timelineHeight + "px)",
            [theme.breakpoints.down('xs')]: {
                height: "calc(100svh - " + timelineHeight + "px - 80px)",
            }
        },
        drawerTitle: {
            maxWidth: drawerWidth - 30,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontSize: "0.9rem"
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            padding: '0 8px',
            height: "48px",
            lineHeight: "48px",
            borderBottom: "1px solid lightgrey"
        },
        pgLogo: {
            height: 40,
            marginLeft: -10,
            paddingTop: 5,
        },
        errorIcon: {
            margin: "0 auto",
            paddingTop: 100,
            width: 300,

        },
        mapType: {
            margin: 4,
        },
        shareIconBox: {
            display: 'flex',
            maxWidth: drawerWidth - 5,
            paddingTop: 5,
            paddingBottom: 14,
            paddingRight: 6,
            height: 20,
            marginLeft: 4,
            marginTop: 10,
            borderBottom: "1px solid lightgrey",
            justifyContent: "space-between",
            alignItems: "center"
        },
    }
};

class TripviewApp extends React.Component {

    constructor(props) {

        super(props);
        //this._forceupdate = false;
        this._menulist = React.createRef();

        let maptype = localStorage.getItem("google_maptypeid");
        if (!maptype) maptype = "satellite";

        this.state = {
            center: {
                lat: 48.138612,
                lng: 11.575838
            },
            zoom: 0,
            tailSecs: 0,
            trip: null,
            drawer: true,
            deviceTracks: null,
            displayedTrack: {},
            infoAll: false,
            trackAll: false,
            tailAll: false,
            timeline: false,
            hidetitle: false,
            currentTS: new Date(),
            minDate: null,
            maxDate: null,
            devices: [],
            timePopperAnchor: null,
            exportDevice: null,
            exportGPX: false,
            errorOccured: false,
            errorText: "",
            maptype,
            open: false,
        };
    }

    getMinDate() {
        const { trip, minDate } = this.state;

        if (!trip) return new Date();
        if (minDate) return minDate;

        return new Date(trip.start);
    }

    getMaxDate() {
        const { trip } = this.state;

        let now = new Date();
        if (!trip || !trip.end || new Date(trip.end).getFullYear() < 2000) return now;

        let d = new Date(trip.end);
        if (now.getTime() < d.getTime()) return now;

        return d;
    }

    connectMqtt(trip) {
        let hn = global.location.hostname;
        // hack, so we support "my.protegear.net" also ...
        if (hn.endsWith("protegear.net")) {
            hn = "protegear.io"
        }
        const url = global.location.hostname == "localhost" ? "ws://localhost:1882" : `wss://mqtt.${hn}`

        const client = mqtt.connect(url)
        const tripTopic = `public/trip/${trip.id}`
        const statsTopic = `public/trip/${trip.id}/stat`

        client.on("connect", function () {
            let topics = [tripTopic, statsTopic + "/#"];
            client.subscribe(topics, function (err) {
                //console.error("subscribe error", err)
            });
        });
        client.on("message", (topic, message) => {
            // message is Buffer
            const data = message.toString() ? JSON.parse(message.toString()) : null;
            console.log(topic, data);
            if (topic == tripTopic) {
                api.gettrip(data.id, false, true).then(t => {
                    let { trip, devices } = t;
                    console.info("got new tripdata", t)
                    this.setState({ trip, devices: devices || [] });
                    // // fetch stats in a separate call
                    // api.gettrip(data.id, true, false).then(t => {
                    //     let { statistics } = t;
                    //     if (!statistics) statistics = {};
                    //     if (this.state.deviceTracks)
                    //         this.state.deviceTracks.update(trip, devices, statistics)
                    //     this.setState({
                    //         statistics
                    //     })
                    //     this.centerAndZoomMap(this.state.deviceTracks)
                    // })
                })
                return
            }
            if (topic.startsWith(statsTopic)) {
                if (this.state.deviceTracks)
                    this.state.deviceTracks.addStatistic(data);
                //this.liveUpdateStats([data]);
            }
        })
    }

    connectPushSockets(trip) {
        this.connectMqtt(trip)
    }

    liveUpdateStats(stats) {
        const { deviceTracks } = this.state;
        for (let s of stats) {
            deviceTracks.addStatistic(s);
        }
        window.requestAnimationFrame(this.refresh.bind(this));
        //this._forceupdate = true;
    }

    getDeviceStartupParameter(pname) {
        let all = 0;
        let devs = {};

        let info = getParameter(pname);

        if (!info) return [all, devs];

        if (info == "all") all = 1;
        else if (info == "") all = 0;
        else {
            all = 2;
            devs = info.split(",").reduce((p, c) => {
                p[c] = true;
                return p;
            }, {})
        }

        return [all, devs];
    }

    deviceList(all, current, devices) {
        if (all == 0) return current;
        if (all == 2) return current;

        return devices.reduce((p, d) => {
            p[d.id] = true
            return p;
        }, {})
    }

    componentDidCatch(err, info) {
        console.log("error: ", err, ", info: ", info)
    }

    _asTS(ts, defval) {
        try {
            if (ts) ts = new Date(ts);
            else ts = defval;
        } catch (e) {
            ts = defval
        }
        return ts;
    }

    componentDidMount() {
        let tid = getParameter("trip");

        api.initWorker(tid)

        let [trackAll, trackDevices] = this.getDeviceStartupParameter("track");
        let [infoAll, infoDevices] = this.getDeviceStartupParameter("info");
        let [tailAll, tailDevices] = this.getDeviceStartupParameter("tail");
        let zoom = getParameter("zoom") || this.state.zoom;
        let tailSecs = getParameter("tailSecs");
        let showdrawer = parseInt(getParameter("devices"), 10) == 1;
        let timeline = parseInt(getParameter("timeline"), 10) == 1;
        let hidetitle = parseInt(getParameter("hidetitle"), 10) == 1;
        let ts = this._asTS(getParameter("ts"), new Date());
        let mints = this._asTS(getParameter("mints"), null);
        let maxts = this._asTS(getParameter("maxts"), null);
        let since = getParameter("since");
        const repainter = this.refresh.bind(this);

        if (since) {
            let d = moment.duration(since);
            if (!moment.isDuration(d)) {
                console.error("invalid duration for since: ", since);
            } else {
                console.info("using duration for min/max:", d);
                mints = moment().subtract(d).toDate();
            }
        }

        console.log("mints: ", mints, ", maxts: ", maxts);

        if (zoom) zoom = parseInt(zoom, 10)
        if (tailSecs) tailSecs = parseInt(tailSecs)

        api.gettrip(tid, false, false).then(t => {
            let { trip, devices, statistics } = t;
            console.log("got trip, connect push socket", trip)
            this.connectPushSockets(trip);

            if (!devices) devices = [];
            if (!statistics) statistics = {};
            infoDevices = this.deviceList(infoAll, infoDevices, devices);
            trackDevices = this.deviceList(trackAll, trackDevices, devices);
            tailDevices = this.deviceList(tailAll, tailDevices, devices);

            let deviceTracks = new DeviceTracks(trip, devices, statistics, infoDevices, trackDevices, tailDevices, dt => repainter());

            this.setState({
                trip,
                devices,
                statistics,
                zoom,
                tailSecs,
                deviceTracks,
                infoAll: infoAll > 0,
                trackAll: trackAll > 0,
                tailAll: tailDevices > 0,
                drawer: showdrawer,
                timeline,
                hidetitle,
                currentTS: ts,
                minDate: mints,
                maxDate: maxts
            })

            // force a refresh so the tracking is shown
            window.requestAnimationFrame(this.refresh.bind(this));
            window.setInterval(() => {
                // every minute a refresh, so we update the state colors for devices from green to red
                //this._forceupdate = true;
                this.refresh();
            }, 1000);//1000 * 60);
        }).catch(e => {
            this.setState({ errorOccured: true, errorText: e.text ? e.text : "" });
            console.log("oops: ", e)
        })
    }

    refresh() {
        if (!this.map) {
            window.requestAnimationFrame(this.refresh.bind(this));
            return; // only if the map is initialized
        }

        const { deviceTracks, displayedTrack, timeline, currentTS, trip } = this.state;
        let ts = deviceTracks.latestTS;
        let now = new Date()
        let tailSeconds = trip.tailSecs;
        if (this.state.tailSecs != null) {
            tailSeconds = this.state.tailSecs;
        }
        if (timeline) {
            ts = currentTS;
            now = ts
        }

        const tracks = deviceTracks.getTracks();
        for (let [imei, track] of Object.entries(tracks)) {
            let dt = displayedTrack[imei];
            if (!track.track) {
                if (dt) {
                    dt.clear();
                    delete (displayedTrack[imei])
                }
                continue;
            }
            let mind = this.getMinDate();
            if (tailSeconds > 0 && track.getTail()) {
                mind = new Date(now.getTime() - tailSeconds * 1000);
            }
            let points = track.getSlice(mind, ts);
            // it seems, that the slice method returns an array, if it finds nothing
            // --> we need a better implementation for the sorted set
            let first = track.getNext(mind);

            if (!first || !points.length) {
                if (dt) {
                    dt.clear();
                    delete (displayedTrack[imei]);
                }
                continue;
            };

            if (!dt) {
                dt = new DisplayedTrack(this.map, mind, currentTS, points, colorFromDevice({ id: imei }), tailSeconds);
                displayedTrack[imei] = dt;
                continue
            }
            dt.refresh(points);
        }
        //if (this._forceupdate || ts.getTime() != currentTS.getTime()) {
        //this._forceupdate = false;
        this.setState({ currentTS: ts });
        //window.requestAnimationFrame(this.refresh.bind(this));
        //this.forceUpdate()
        //}
    }

    handleMapsApiLoaded(map, maps) {
        if (!map) return;

        this.map = map;
        this.maps = maps;

        map.addListener('maptypeid_changed', function (evt) {
            localStorage.setItem("google_maptypeid", map.getMapTypeId());
            //console.log("maptype: ", map.getMapTypeId());
        });

        let mt = localStorage.getItem("google_maptypeid");
        if (mt) {
            map.setMapTypeId(mt);
        }

        this.centerAndZoomMap();
    }

    centerAndZoomMap(dtracks) {
        console.log("center and zoom:", dtracks)
        const { deviceTracks } = this.state;
        const devtracks = dtracks || deviceTracks;
        let calledZoom = this.state.zoom;

        let { bounds, center, zoom } = devtracks.getCenterAndBounds();
        if (!center) {
            // we have nothing ....
            return
        }
        const map = this.map;
        if (!map) {
            window.requestAnimationFrame(this.centerAndZoomMap.bind(this, devtracks));
            return;
        }
        map.fitBounds(bounds);
        map.panTo(center);
        if (zoom == 0 || calledZoom != 0) zoom = calledZoom;
        if (zoom)
            map.setOptions({ zoom });
        this.setState({ center: center.toJSON() });

    }

    toggleDrawer() {
        this.setState({ drawer: !this.state.drawer })
    }

    onCenterDevice(device) {
        const { deviceTracks } = this.state;

        deviceTracks.getCurrentPosition(device.id).then(mc => {
            this.map.setCenter(mc);
            this.map.panTo(mc);
        });
    }

    onInfo(device, t) {
        if (this._menulist) this._menulist.current.showDevice(device, t);
    }

    onShowInfoClicked(device) {
        const { deviceTracks } = this.state;
        const track = deviceTracks.getTrack(device.id);
        track.setInfo(!track.info);
        if (track.info) {
            deviceTracks.getCurrentPosition(device.id).then(mc => {
                this.map.setCenter(mc);
                this.map.panTo(mc);
            });
        }
        this.setState({ deviceTracks });
    }

    onShowTrackClicked(device) {
        const { deviceTracks } = this.state;
        const track = deviceTracks.getTrack(device.id);
        track.setTracking(!track.track, (t) => {
            this.refresh()
        });
        this.setState({ deviceTracks });
        this.refresh();
    }

    onShowTrackVisible(device) {
        const { deviceTracks } = this.state;
        const track = deviceTracks.getTrack(device.id);
        track.setVisible(!track.visible);
        if (!track.visible)
            track.setTracking(false);
        this.setState({ deviceTracks });
    }

    onShowTail(device) {
        const { deviceTracks } = this.state;
        const track = deviceTracks.getTrack(device.id);
        track.setTail(!track.getTail())
        this.setState({ deviceTracks });
        this.refresh();
    }


    onHideOthers(dev) {
        const { deviceTracks } = this.state;
        for (let d in deviceTracks.getTracks()) {
            if (d != dev.id) {
                deviceTracks.getTrack(d).setVisible(false)
                deviceTracks.getTrack(d).setTracking(false)
                deviceTracks.getTrack(d).setInfo(false)
            }
        }
        this.setState({ deviceTracks });
    }

    onShowOthers(dev) {
        const { deviceTracks } = this.state;
        for (let d in deviceTracks.getTracks()) {
            if (d != dev.id) {
                deviceTracks.getTrack(d).setVisible(true)
            }
        }
        this.setState({ deviceTracks });
    }

    onDownloadGPX(mindate, maxdate, dev) {
        const { deviceTracks, trip } = this.state;

        let name = trip.name;
        let devid = "";

        if (dev) {
            name = dev.name || dev.id;
            devid = dev.id || "";
        }

        api.gpx(trip.id, devid, mindate, maxdate, name).then(gpx => {
            let blob = new Blob([gpx], { type: "application/gpx+xml;charset=utf-8" });
            saveAs(blob, name + ".gpx");
            this.setState({ exportDevice: null, exportGPX: false });
        })
    }

    onShowTrackAll() {
        const { deviceTracks } = this.state;
        for (let d in deviceTracks.getTracks()) {
            deviceTracks.getTrack(d).setTracking(!this.state.trackAll, (t) => {
                this.refresh()
            })
        }
        this.setState({ deviceTracks, trackAll: !this.state.trackAll });
        this.refresh();
    }

    onTailAll() {
        const { deviceTracks } = this.state;
        for (let d in deviceTracks.getTracks()) {
            deviceTracks.getTrack(d).setTail(!this.state.tailAll, (t) => {
                this.refresh()
            })
        }
        this.setState({ deviceTracks, tailAll: !this.state.tailAll });
        this.refresh();
    }


    onShowInfoAll() {
        const { deviceTracks } = this.state;
        for (let d in deviceTracks.getTracks()) {
            deviceTracks.getTrack(d).setInfo(!this.state.infoAll)
        }
        this.setState({ deviceTracks, infoAll: !this.state.infoAll });
    }

    createMapOptions(maps) {
        let mt = localStorage.getItem("google_maptypeid");
        if (mt) {
            // map.setMapTypeId(mt);
        } else {
            mt = maps.MapTypeId.HYBRID;
        }
        return {
            scrollwheel: true,
            scaleControl: true,
            rotateControl: true,
            mapTypeId: mt,
            mapTypeControl: false,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                position: maps.ControlPosition.RIGHT_TOP,
                mapTypeIds: ['roadmap', 'terrain', 'satellite', 'hybrid'],
            },
            styles: [{ stylers: [{ 'saturation': -50 }, { 'gamma': 0.9 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
        }
    }

    onToggleTimeline(evt) {
        //this.setState({ timeline: !this.state.timeline, minDate: null, maxDate: null })
        this.setState({ timePopperAnchor: evt.target })
    }

    onSetTimestamp(t) {
        this.setState({ currentTS: t });
        window.requestAnimationFrame(this.refresh.bind(this));
    }

    onSelectTimeRange(dev, start, end) {
        const { deviceTracks } = this.state;
        const track = deviceTracks.getTrack(dev.id);
        track.setTracking(true);
        this.setState({ timeline: true, minDate: start, maxDate: end, deviceTracks })
    }

    onSelectTimeRangeHours(hrs) {
        if (hrs == -1) {
            this.setState({ timeline: false, timePopperAnchor: null, minDate: null, maxDate: null })
            return;
        }
        const { deviceTracks, trip } = this.state;
        let begin = new Date(trip.start);
        let end = this.getMaxDate();

        if (hrs != 0) {
            begin = new Date(end.getTime() - hrs * 3600 * 1000);
        }

        for (let d in deviceTracks.getTracks()) {
            deviceTracks.getTrack(d).setTracking(true)
        }

        this.setState({ timeline: true, minDate: begin, maxDate: end, deviceTracks, trackAll: true, timePopperAnchor: null });
    }

    changeMap(tp) {
        this.setState({ maptype: tp });
        this.map.setMapTypeId(tp);
        localStorage.setItem("google_maptypeid", tp);
    }

    copyLink() {
        navigator.clipboard.writeText(window.location.href);
    }

    openMailer() {
        window.open("mailto:?subject=My Trip&body=Check out my Trip here: " + encodeURIComponent(window.location.href));
    }

    setOpen(event) {
        this.setState({ open: event });
    }

    render() {
        const { classes } = this.props;
        const { center, zoom, trip, devices, statistics, drawer, timeline, deviceTracks, errorOccured, errorText } = this.state;

        const handleClick = () => {
            this.setOpen(true);
        };

        const handleClose = () => {
            this.setOpen(false);
        };

        if (errorOccured) {
            return (
                <div className={classes.root}>
                    <div className={classes.errorIcon}>
                        <img width="300px" src={error_occured} />
                        <br />
                        <div style={{ textAlign: "center" }}>Something went wrong ... ({errorText})</div>
                    </div>
                </div>
            )
        }

        if (!trip) {
            return (<div className={classes.root}><GridLoader
                css={spinner}
                sizeUnit={"px"}
                size={30}
                margin={"10px"}
                color={'#aec8f2'}
                loading={true}
            /></div>)
        }

        let minDate = this.getMinDate();

        let mapClasses = [classes.map];
        if (drawer) mapClasses.push(classes.mapWithDrawer)
        if (timeline) mapClasses.push(classes.mapWithTimeline);

        let mapClass = clsx(...mapClasses)

        let allstats = deviceTracks.allWithPositions();

        let devmap = devices.reduce((p, d) => {
            p[d.id] = d;
            return p;
        }, {})

        let timelineComp = null;
        if (timeline)
            timelineComp = (
                <TimelineControl
                    trip={trip}
                    minDate={this.state.minDate}
                    maxDate={this.state.maxDate}
                    initTS={this.state.currentTS}
                    onSetTimestamp={(t) => this.onSetTimestamp(t)} />);

        const canTail = trip.tailSecs > 0 || this.state.tailSecs != null

        return (
            <div className={classes.root}>
                <ExportDialog
                    onClose={() => this.setState({ exportDevice: null, exportGPX: false })}
                    onExport={(from, until, device) => this.onDownloadGPX(from, until, device)}
                    device={this.state.exportDevice}
                    open={this.state.exportGPX}
                    trip={this.state.trip}
                    min={minDate}
                    max={this.state.currentTS} />
                <TimePopper
                    withNone
                    anchor={this.state.timePopperAnchor}
                    onSelect={(hrs) => this.onSelectTimeRangeHours(hrs)}
                    onClose={() => this.setState({ timePopperAnchor: null })} />
                <TitleBar
                    visible={!drawer}
                    hidetitle={this.state.hidetitle}
                    onMenuClick={() => this.toggleDrawer()}>{trip.name}</TitleBar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={drawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <span><a href="https://www.protegear.de" target="_blank" title="Open Protegear"><img src={pg_logo_mini} className={classes.pgLogo} /></a></span>
                        <span className={classes.drawerTitle}>{trip.name}</span>
                        <span style={{ flex: 1 }}></span>
                        <span><IconButton onClick={() => this.toggleDrawer()}>
                            <ChevronLeftIcon />
                        </IconButton></span>
                    </div>
                    <FormControl className={classes.mapType}>
                        <InputLabel htmlFor="maptype-native-simple">Map type</InputLabel>
                        <Select native value={this.state.maptype} onChange={(evt) => this.changeMap(evt.target.value)}>
                            <option value="roadmap">Roadmap</option>
                            <option value="satellite">Satellite</option>
                            <option value="hybrid">Hybrid</option>
                            <option value="terrain">Terrain</option>
                        </Select>
                    </FormControl>
                    <div className={classes.shareIconBox}>
                        Share your Trip
                        <div style={{ display: "flex", alignItems: "right" }}>
                            <IconButton
                                size="small"
                                href={"http://www.facebook.com/sharer.php?u=" + encodeURIComponent(window.location.href)}
                                target="_blank"
                                title="Share on Facebook">
                                <FacebookIcon htmlColor="rgb(24, 119, 242)" />
                            </IconButton>
                            <IconButton
                                title="Share on Twitter"
                                href={"https://twitter.com/intent/tweet?text=" + encodeURIComponent("Check out my Trip here:")
                                    + "&url=" + encodeURIComponent(window.location.href)}
                                target="_blank"
                                size="small">
                                <TwitterIcon htmlColor='rgb(29, 161, 242)' />
                            </IconButton>
                            <IconButton
                                title="Share via E-Mail"
                                onClick={() => { this.openMailer() }}
                                size="small">
                                <MailIcon htmlColor='rgb(70, 70, 70)' />
                            </IconButton>
                            <IconButton
                                title="Copy link"
                                onClick={() => { this.copyLink(), handleClick() }}
                                size="small">
                                <LinkIcon />
                                <Snackbar
                                    open={this.state.open}
                                    onClose={handleClose}
                                    autoHideDuration={2000}>
                                    <Alert
                                        onClose={handleClose}
                                        severity="success">
                                        Successfully copied the link
                                    </Alert>
                                </Snackbar>
                            </IconButton>
                        </div>
                    </div>
                    <MenuDeviceList
                        onShowInfoClicked={(dev) => this.onShowInfoClicked(dev)}
                        onShowTrackClicked={(dev) => this.onShowTrackClicked(dev)}
                        onShowTrackVisible={(dev) => this.onShowTrackVisible(dev)}
                        onShowTail={canTail ? (dev) => this.onShowTail(dev) : null}
                        onShowOthers={(dev) => this.onShowOthers(dev)}
                        onHideOthers={(dev) => this.onHideOthers(dev)}
                        onDownloadGPX={(dev) => this.setState({ exportDevice: dev, exportGPX: true })}
                        onShowTrackAll={() => this.onShowTrackAll()}
                        onShowInfoAll={() => this.onShowInfoAll()}
                        onTailAll={canTail ? () => this.onTailAll() : null}
                        onToggleTimeline={(evt) => this.onToggleTimeline(evt)}
                        onSelectTimeRange={this.onSelectTimeRange.bind(this)}
                        ref={this._menulist}
                        onCenter={(d) => this.onCenterDevice(d)}
                        trip={trip}
                        devices={devices}
                        tracks={deviceTracks}
                    />
                </Drawer>

                <div className={mapClass}>
                    <GoogleMapReact
                        xyesIWantToUseGoogleMapApiInternals
                        options={(m) => this.createMapOptions(m)}
                        onGoogleApiLoaded={({ map, maps }) => this.handleMapsApiLoaded(map, maps)}
                        bootstrapURLKeys={{ key: mapsKey }}
                        defaultCenter={center}
                        defaultZoom={11}
                    >
                        {allstats.map(dt => {
                            let d = dt.statistic;
                            let id = dt.getImei(); // + "-" + dt.getID();
                            let dev = devmap[d.imei];
                            // if a new trip is pushed where devices has been removed,
                            // the dev here can be empty. the user should do a reload
                            if (!dev) return null;
                            let lbl = dev.name ? dev.name : dev.id;
                            let event = dt.getAt(this.state.currentTS);
                            if (!event) return null;
                            if (timeline) {
                                // check if the event is before the begin of our timeline
                                if (event.sent.getTime() < minDate.getTime()) {
                                    return null;
                                }
                            }
                            let pos = event.position;
                            if (!pos) return null;
                            return <DeviceMarker
                                onInfo={this.onInfo.bind(this)}
                                deviceTrack={dt}
                                key={id}
                                trip={trip}
                                event={event}
                                lat={pos.lat}
                                lng={pos.lng}
                                text={lbl} device={dev} />
                        })}
                    </GoogleMapReact>
                    {timelineComp}
                </div>
            </div>
        );

    }
}
export default withStyles(styles)(TripviewApp);
