export function fetchInit(method, body) {
  let h = new Headers({
    "Content-Type": "application/json"
  });
  let res = {
    method: method,
    headers: h,
    cache: 'default'
  };
  if (body) {
    res.body = JSON.stringify(body);
  }
  return res;
}

export function check(rsp) {
  if (rsp.ok) {
    return rsp.json();
  }
  if (rsp.status === 410) {// GONE
    return Promise.reject({ status: rsp.status, text: "The trip is inactive" });
  }
  return Promise.reject({ status: rsp.status, text: rsp.statusText });
}

export function gettrip(tripid, nodevs, nostats) {
  return fetch(`/tripview/api/v1/trip/${tripid}?nodevs=${nodevs}&nostats=${nostats}`, fetchInit("GET")).then(check);
}

export function gpx(tripid, imei, from, until, name) {
  let f = from.toISOString();
  let t = until.toISOString();
  return fetch(`/tripview/api/v1/trip/${tripid}/gpx?imei=${imei}&from=${f}&until=${t}&name=${name}`, { method: "GET", cache: "default" }).then(rsp => rsp.text());
}

export function getPositionAt(tripid, imei, tm) {
  let t = "";
  if (tm) t = encodeURIComponent(tm.toISOString());
  return fetch(`/tripview/api/v1/trip/${tripid}/${imei}/at?ts=${t}`, fetchInit("GET")).then(rsp => {
    if (rsp.ok) return rsp.json();
    // do not reject, because caller always wants a value
    return Promise.resolve({});
  });
}

export function getStat(tripid, imei) {
  return fetch(`/tripview/api/v1/trip/${tripid}/${imei}/stat`, fetchInit("GET")).then(rsp => {
    if (rsp.ok) return rsp.json();
    // do not reject, because caller always wants a value
    return Promise.resolve({});
  });
}

let eventFetcher = null;

export function getasyncevents(tripid, imeis, callback) {
  let devs = imeis.map(d => {
    return { imei: d, limit: 1000, skip: 0 }
  });
  stopAsyncEvents();
  eventFetcher = new EventFetcher(tripid, devs, callback);
  return eventFetcher.getevents();
}

export function stopAsyncEvents() {
  if (eventFetcher) eventFetcher.stop();
}
class EventFetcher {
  constructor(tripid, devices, callback) {
    this.tripid = tripid;
    this.devices = devices;
    this.callback = callback;
    this.running = true;
  }

  getevents() {
    if (!this.running) return;
    if (this.devices.length < 1) return;
    let dev = this.devices.shift();
    return fetch(`/tripview/api/v1/trip/${this.tripid}/${dev.imei}/events?limit=${dev.limit}&skip=${dev.skip}`, fetchInit("GET"))
      .then(check)
      .then(evts => {
        this.callback(dev.imei, evts)
        if (evts.length >= dev.limit) {
          this.devices.push({ imei: dev.imei, limit: dev.limit, skip: dev.skip + evts.length });
          //return ; //Promise.resolve(true);
        }
        if (this.devices.length > 0)
          this.getevents();
      });
  }
  stop() {
    this.running = false;
  }
}

const limit = 500
class EventFetchWorker {
  constructor(tripid) {
    this.tripid = tripid;
    this.queue = [];
    this.ignoreImeis = {};

    window.setInterval(() => {
      this._go();
    }, 1500);
  }

  removeJobs(imei) {
    this.queue = this.queue.filter(j => j.imei !== imei);
    this.ignoreImeis = { ...this.ignoreImeis, [imei]: true };
  }

  addJob(imei, cb, until) {
    delete this.ignoreImeis[imei];
    this._addJob(imei, cb, until);
  }

  _addJob(imei, cb, until) {
    if (this.ignoreImeis[imei]) return;
    const f = until ? new Date(until).toISOString() : "";
    this.queue.push({ imei, cb, until: f });
  }

  _go() {
    if (this.queue.length < 1) {
      return;
    }
    const job = this.queue.shift();
    const imei = job.imei;
    const cb = job.cb;
    const until = job.until
    let from = "";
    if (until) {
      //const oneDayMillis = 1000 * 3600 * 24
      //from = new Date(new Date(until).getTime() - oneDayMillis).toISOString();
    }

    fetch(`/tripview/api/v1/trip/${this.tripid}/${imei}/events?limit=${limit}&skip=0&from=${from}&until=${until}`, fetchInit("GET"))
      .then(check)
      .then(evts => {
        if (this.ignoreImeis[imei]) return;
        cb(evts);
        if (evts.length >= limit) {
          const last = evts[evts.length - 1]
          this._addJob(imei, cb, last.sent);
        }
      });
  }
}

let workerQueue = null;

export function initWorker(tripid) {
  workerQueue = new EventFetchWorker(tripid);
}

export function addWorkerJob(imei, cb, until) {
  workerQueue.addJob(imei, cb, until);
}

export function clearWorkerJobs(imei) {
  workerQueue.removeJobs(imei);
}
