"use strict";

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Battery20 from '@material-ui/icons/Battery20';
import Battery30 from '@material-ui/icons/Battery30';
import Battery50 from '@material-ui/icons/Battery50';
import Battery60 from '@material-ui/icons/Battery60';
import Battery80 from '@material-ui/icons/Battery80';
import Battery90 from '@material-ui/icons/Battery90';
import BatteryCharging20Icon from '@material-ui/icons/BatteryCharging20';
import BatteryCharging30Icon from '@material-ui/icons/BatteryCharging30';
import BatteryCharging50Icon from '@material-ui/icons/BatteryCharging50';
import BatteryCharging60Icon from '@material-ui/icons/BatteryCharging60';
import BatteryCharging80Icon from '@material-ui/icons/BatteryCharging80';
import BatteryCharging90Icon from '@material-ui/icons/BatteryCharging90';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import BatteryFull from '@material-ui/icons/BatteryFull';
import BatteryUnknown from '@material-ui/icons/BatteryUnknown';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import CloudDownload from '@material-ui/icons/CloudDownload';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import GpsOffIcon from '@material-ui/icons/GpsOff';
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import PeopleIcon from '@material-ui/icons/People';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import TimelineIcon from '@material-ui/icons/Timeline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import WifiIcon from '@material-ui/icons/Wifi';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import TimePopper from './TimePopper';
import { colorFromDevice } from './components/DeviceMarker';



const styles = theme => {
    return {
        root: {
            overflowY: "auto",
            height: "100%",
            padding: 2,
        },
        listTitle: {
            fontSize: "0.9rem",
            borderBottom: "1px solid lightgrey",
            padding: 5,
            display: "flex",
            alignItems: "center"
        },
        devicePanel: {
            border: "1px solid grey",
            borderRadius: 3,
            boxShadow: "2px 2px 1px lightgrey",
            backgroundColor: "#e3eae8",
            margin: 5,
            padding: 2,
            fontSize: "0.8rem",
            minWidth: 150,
        },
        devicePanelName: {
            display: "flex",
            lineHeight: "20px",
            cursor: "pointer"
        },
        devicePanelLastContact: {
            fontSize: "0.75rem",
            marginLeft: 5,
            marginRight: 5,
        },
        moreActionsButton: {
            cursor: "pointer"
        },
        moreActionsPanel: {
            padding: 3,
        },
        moreAction: {
            xmargin: 2,
            marginLeft: 5,
            marginRight: 5
        },
        moreActionButtonBar: {
            display: "flex",
            justifyContent: "center"
        },
        iconSmall: {
            fontSize: 20,
        },
        grow: {
            transition: "all .2s ease -in -out",
            transform: "scale(1.2)",
            marginTop: 25,
            marginBottom: 25,
            marginLeft: 25,
            marginRight: 25
        }
    }
};

const lastContactDiffMins = 10;
const menuItemIconStyle = {
    verticalAlign: 'middle',
};

class DeviceElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            moreMenu: props.info,
            info: props.info,
            timePopperAnchor: null
        }
        this._ref = React.createRef()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.info != this.props.info) {
            this.setState({ moreMenu: this.props.info, info: this.props.info })
        }
    }

    moreMenu(evt, device) {
        this.setState({ moreMenu: !this.state.moreMenu })
    }

    onCenter(device) {
        const { onCenter } = this.props;
        if (onCenter) {
            onCenter(device);
        }
    }

    showIntoView(t) {
        const { classes } = this.props;
        let grow = classes.grow;

        if (this._ref) {
            let elem = this._ref.current;
            if (t) {
                elem.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }
        }
        this.setState({ moreMenu: t, info: t })
    }

    onHideOthers(dev) {
        const { onHideOthers } = this.props;
        if (onHideOthers) onHideOthers(dev)
    }

    onShowOthers(dev) {
        const { onShowOthers } = this.props;
        if (onShowOthers) onShowOthers(dev)
    }

    onShowInfo(dev) {
        const { onShowInfoClicked } = this.props;
        if (onShowInfoClicked) onShowInfoClicked(dev)
        this.setState({ info: !this.state.info });
    }
    onShowTrack(dev) {
        const { onShowTrackClicked } = this.props;
        if (onShowTrackClicked) onShowTrackClicked(dev)
    }
    onShowTail(dev) {
        const { onShowTail } = this.props;
        if (onShowTail) onShowTail(dev)
    }

    onVisibleTrack(dev) {
        const { onVisibleTrack } = this.props;
        if (onVisibleTrack) onVisibleTrack(dev)
    }
    onDownloadGPX(dev) {
        const { onDownloadGPX } = this.props;
        if (onDownloadGPX) onDownloadGPX(dev)
    }
    onSelectTimeRange(hrs) {
        const { onSelectTimeRange, statistic, device } = this.props;

        this.setState({ timePopperAnchor: null })

        if (!onSelectTimeRange) return;

        let begin = null;
        let end = null;

        if (hrs == 0) {
            if (statistic && statistic.firstEvent) begin = new Date(statistic.firstEvent.sent);
            if (statistic && statistic.latestContact) end = new Date(statistic.latestContact.sent);
        } else {
            if (statistic && statistic.latestContact) {
                end = new Date(statistic.latestContact.sent);
                begin = new Date(end.getTime() - hrs * 3600 * 1000);
            }
        }
        onSelectTimeRange(device, begin, end);
    }

    onSelectTime(evt, dev) {
        this.setState({ timePopperAnchor: evt.target })
    }

    batteryIcon(stat) {
        if (!stat || !stat.latestContact || !stat.latestContact.battery)
            return ["no data", (<BatteryUnknown fontSize="small" title="Unknown" style={{ color: "red" }} />)];

        let b = stat.latestContact.battery;
        let load = b.loadpercent + "%";
        const loading = stat.latestContact?.state?.charging == "ON";
        let loadCharging = b.loadpercent + "% " + "(currently charging)";
        if (b.loadpercent == 0 && !b.low) {
            // some devices only signal low or not low
            return ["no data", (<Battery50 fontSize="small" title="charged" style={{ color: "green" }} />)]
        }
        if (b.loadpercent < 20) {
            return loading ? [loadCharging, (<BatteryCharging20Icon fontSize="small" title={loadCharging} style={{ color: "red" }} />)] : [load, (<Battery20 fontSize="small" title={load} style={{ color: "red" }} />)]
        } if (b.loadpercent < 30) {
            return loading ? [loadCharging, (<BatteryCharging30Icon fontSize="small" title={loadCharging} style={{ color: "saddlebrown" }} />)] : [load, (<Battery30 fontSize="small" title={load} style={{ color: "yellow" }} />)]
        } if (b.loadpercent < 50) {
            return loading ? [loadCharging, (<BatteryCharging50Icon fontSize="small" title={loadCharging} style={{ color: "saddlebrown" }} />)] : [load, (<Battery50 fontSize="small" title={load} style={{ color: "yellow" }} />)]
        } if (b.loadpercent < 60) {
            return loading ? [loadCharging, (<BatteryCharging60Icon fontSize="small" title={loadCharging} style={{ color: "green" }} />)] : [load, (<Battery60 fontSize="small" title={load} style={{ color: "green" }} />)]
        } if (b.loadpercent < 80) {
            return loading ? [loadCharging, (<BatteryCharging80Icon fontSize="small" title={loadCharging} style={{ color: "green" }} />)] : [load, (<Battery80 fontSize="small" title={load} style={{ color: "green" }} />)]
        } if (b.loadpercent < 90) {
            return loading ? [loadCharging, (<BatteryCharging90Icon fontSize="small" title={loadCharging} style={{ color: "green" }} />)] : [load, (<Battery90 fontSize="small" title={load} style={{ color: "green" }} />)]
        }
        return loading ? [loadCharging, (<BatteryChargingFullIcon fontSize="small" title={loadCharging} style={{ color: "green" }} />)] : [load, (<BatteryFull fontSize="small" title={load} style={{ color: "green" }} />)]
    }

    durationWithPadding(duration) {
        if (duration.asDays() > 1) {
            let ds = parseInt(duration.asDays(), 10);
            return `>${ds} days`;
        } else {
            return [
                ('0' + duration.hours()).slice(-2),
                ('0' + duration.minutes()).slice(-2),
                ('0' + duration.seconds()).slice(-2),
            ].join(':')
        }
    }

    render() {
        const { device, classes, statistic, track, visible, tail } = this.props;
        const { info } = this.state;


        let name = device.name ? device.name : device.id;
        let lastcontact = " -- no contact --";
        let ttitle = `no contact since ${lastContactDiffMins} minutes`;
        let lines = 1;
        let icStyle = { ...menuItemIconStyle, color: "red" };
        let timepopup = false;
        if (statistic && statistic.latestContact) {
            let latest = new Date(statistic.latestContact.sent);
            if (latest.getFullYear() > 2000) {
                timepopup = true;
                lastcontact = latest.toLocaleString();
                let msecdiff = new Date() - latest;
                let mindiff = msecdiff / 1000 / 60;
                if (mindiff <= lastContactDiffMins) {
                    icStyle.color = "green";
                    ttitle = `contact in the last ${lastContactDiffMins} minutes`;
                }
                if (statistic.deltasec > 0) {
                    let d = moment.duration(parseInt(statistic.deltasec.toFixed(0), 10), 'seconds');
                    let delta = "\u0394" + this.durationWithPadding(d)
                    lastcontact = (<div style={{ display: "flex" }}><span>{lastcontact}</span><span style={{ flex: 1 }}></span><span>{delta}</span></div>)
                }
            }
        }

        let moreActions = (<KeyboardArrowDownIcon fontSize="small" className={classes.moreActionsButton} />);
        if (this.state.moreMenu) {
            moreActions = (<KeyboardArrowUpIcon fontSize="small" className={classes.moreActionsButton} />);
        }
        let devcolor = colorFromDevice(device);
        let s = {
            backgroundImage: "linear-gradient(to right,#e3eae8," + devcolor + ")"
        }

        let className = classes.devicePanel;
        let infoIcon = (<InfoOutlinedIcon className={classes.iconSmall} />);
        let trackIcon = (<GpsOffIcon className={classes.iconSmall} />);
        let visIcon = (<VisibilityIcon className={classes.iconSmall} />);
        let tailIcon = (<LinearScaleIcon className={classes.iconSmall} />);

        let [batteryload, batteryIcon] = this.batteryIcon(statistic);

        if (!tail) {
            tailIcon = (<LinearScaleIcon color="disabled" className={classes.iconSmall} />);
        }

        if (!visible) {
            visIcon = (<VisibilityOffIcon className={classes.iconSmall} />);
        }
        if (info) {
            className = clsx(classes.devicePanel, classes.grow)
            infoIcon = (<InfoIcon className={classes.iconSmall} />)
        }
        if (track) {
            trackIcon = (<GpsFixedIcon className={classes.iconSmall} />)
        }

        return (
            <div className={className} style={s} ref={this._ref}>
                <TimePopper
                    anchor={this.state.timePopperAnchor}
                    onSelect={(hrs) => this.onSelectTimeRange(hrs)}
                    onClose={() => this.setState({ timePopperAnchor: null })} />
                <div className={classes.devicePanelName} onClick={(evt) => this.moreMenu(evt, device)}>
                    <span title={ttitle}><WifiIcon fontSize="small" style={icStyle} /></span>
                    <span title={batteryload}>{batteryIcon}</span>
                    <span>{name}</span>
                    <span style={{ flex: 1 }} />
                    {moreActions}
                </div>
                <div className={classes.devicePanelLastContact}>{lastcontact}</div>
                <Collapse in={this.state.moreMenu}>
                    <div className={classes.moreActionsPanel}>
                        <div className={classes.moreActionButtonBar}>
                            <IconButton
                                className={classes.moreAction}
                                onClick={() => this.onShowInfo(device)}
                                size="small"
                                color="primary"
                                aria-label="Info"
                                title="Info">
                                {infoIcon}
                            </IconButton>
                            <IconButton
                                className={classes.moreAction}
                                onClick={() => this.onShowTrack(device)}
                                size="small"
                                color="primary"
                                aria-label="Track"
                                title="Track">
                                {trackIcon}
                            </IconButton>
                            <IconButton
                                className={classes.moreAction}
                                onClick={() => this.onShowTail(device)}
                                disabled={this.props.onShowTail == null}
                                size="small"
                                color="primary"
                                aria-label="Tail"
                                title="Tail">
                                {tailIcon}
                            </IconButton>
                            <IconButton
                                className={classes.moreAction}
                                onClick={() => this.onVisibleTrack(device)}
                                size="small"
                                color="primary"
                                aria-label="Show/Hide"
                                title="Show/Hide">
                                {visIcon}
                            </IconButton>
                            <IconButton
                                className={classes.moreAction}
                                onClick={() => this.onCenter(device)}
                                size="small"
                                color="primary"
                                aria-label="Center"
                                title="Center">
                                <CenterFocusStrongIcon className={classes.iconSmall} />
                            </IconButton>
                        </div>
                        <div className={classes.moreActionButtonBar}>
                            <IconButton
                                className={classes.moreAction}
                                onClick={() => this.onShowOthers(device)}
                                variant="outlined"
                                size="small"
                                color="primary"
                                aria-label="Show others"
                                title="Show others">
                                <PeopleIcon className={classes.iconSmall} />
                            </IconButton>
                            <IconButton
                                className={classes.moreAction}
                                onClick={() => this.onHideOthers(device)}
                                variant="outlined"
                                size="small"
                                color="primary"
                                aria-label="Hide others"
                                title="Hide others">
                                <PeopleOutlineIcon className={classes.iconSmall} />
                            </IconButton>
                            <IconButton
                                disabled={!timepopup}
                                className={classes.moreAction}
                                onClick={(evt) => this.onSelectTime(evt, device)}
                                variant="outlined"
                                size="small"
                                color="primary"
                                aria-label="Select time"
                                title="Select time">
                                <TimelineIcon className={classes.iconSmall} />
                            </IconButton>
                            <IconButton
                                className={classes.moreAction}
                                onClick={() => this.onDownloadGPX(device)}
                                variant="outlined"
                                disabled={!this.props.enableGPXExport}
                                size="small"
                                color="primary"
                                aria-label="Export GPX"
                                title="Export GPX">
                                <CloudDownload className={classes.iconSmall} />
                            </IconButton>
                        </div>
                    </div>
                </Collapse>
            </div>
        )
    }
}


class MenuDeviceList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this._childrefs = {}
    }

    onCenter() {

    }

    onTrack() {

    }

    onShowOthers() {

    }

    onHideOthers() {

    }

    showDevice(d, t) {
        let ref = this._childrefs[d.id];
        if (ref)
            ref.showIntoView(t)
    }

    setChildRef(id, element) {
        this._childrefs[id] = element;
    }

    onShowInfoClicked(dev) {
        const { onShowInfoClicked } = this.props;
        if (onShowInfoClicked) onShowInfoClicked(dev);
    }
    onShowTrackClicked(dev) {
        const { onShowTrackClicked } = this.props;
        if (onShowTrackClicked) onShowTrackClicked(dev);
    }
    onShowTrackVisible(dev) {
        const { onShowTrackVisible } = this.props;
        if (onShowTrackVisible) onShowTrackVisible(dev);
    }

    onHideOthers(dev) {
        const { onHideOthers } = this.props;
        if (onHideOthers) onHideOthers(dev)
    }

    onShowOthers(dev) {
        const { onShowOthers } = this.props;
        if (onShowOthers) onShowOthers(dev)
    }

    onShowInfoAll() {
        const { onShowInfoAll } = this.props;
        if (onShowInfoAll) onShowInfoAll()
    }

    onShowTrackAll() {
        const { onShowTrackAll } = this.props;
        if (onShowTrackAll) onShowTrackAll()
    }

    onTailAll() {
        const { onTailAll } = this.props;
        if (onTailAll) onTailAll()
    }

    onShowTail(dev) {
        const { onShowTail } = this.props;
        if (onShowTail) onShowTail(dev)
    }

    onToggleTimeline(evt) {
        const { onToggleTimeline } = this.props;
        if (onToggleTimeline) onToggleTimeline(evt)
    }

    onDownloadGPX(evt) {
        const { onDownloadGPX } = this.props;
        if (onDownloadGPX) onDownloadGPX(evt)
    }

    onSelectTimeRange(dev, start, end) {
        const { onSelectTimeRange } = this.props;
        if (onSelectTimeRange) onSelectTimeRange(dev, start, end)
    }


    render() {
        const { classes, devices, tracks, onCenter } = this.props;
        const { anchorEl } = this.state;

        let orderedDevs = devices.sort((d1, d2) => {
            let n1 = d1.name ? d1.name : d1.id;
            let n2 = d2.name ? d2.name : d2.id;
            return n1.localeCompare(n2);
        });
        const open = Boolean(anchorEl);

        return (
            <div className={classes.root}>
                <div className={classes.listTitle}>
                    <div>Devices</div>
                    <div style={{ flex: 1 }} />
                    <div>
                        <IconButton
                            className={classes.moreAction}
                            onClick={() => this.onShowInfoAll()}
                            size="small"
                            color="primary"
                            aria-label="Toggle info all"
                            title="Toggle info all">
                            <InfoIcon className={classes.iconSmall} />
                        </IconButton>
                        <IconButton
                            className={classes.moreAction}
                            onClick={() => this.onShowTrackAll()}
                            size="small"
                            color="primary"
                            aria-label="Toggle track all"
                            title="Toggle track all">
                            <GpsFixedIcon className={classes.iconSmall} />
                        </IconButton>
                        <IconButton
                            className={classes.moreAction}
                            onClick={() => this.onTailAll()}
                            disabled={this.props.onTailAll == null}
                            size="small"
                            color="primary"
                            aria-label="Toggle tail all"
                            title="Toggle tail all">
                            <LinearScaleIcon className={classes.iconSmall} />
                        </IconButton>
                        <IconButton
                            className={classes.moreAction}
                            onClick={(evt) => this.onToggleTimeline(evt)}
                            size="small"
                            color="primary"
                            aria-label="Toggle timeline"
                            title="Toggle timeline">
                            <TimelineIcon className={classes.iconSmall} />
                        </IconButton>
                        <IconButton
                            className={classes.moreAction}
                            onClick={() => this.onDownloadGPX({ name: this.props.trip.name })}
                            variant="outlined"
                            disabled={!this.props.trip.enable_gpx_export}
                            size="small"
                            color="primary"
                            aria-label="Export GPX"
                            title="Export GPX">
                            <CloudDownload className={classes.iconSmall} />
                        </IconButton>
                    </div>
                </div>
                {orderedDevs.map(d => {
                    const track = tracks.getTrack(d.id);
                    if (!track) return null
                    const statistic = track.statistic;
                    return (
                        <DeviceElement classes={classes} key={d.id}
                            onShowInfoClicked={(dev) => this.onShowInfoClicked(dev)}
                            onShowTrackClicked={(dev) => this.onShowTrackClicked(dev)}
                            onShowTail={this.props.onShowTail != null ? (dev) => this.onShowTail(dev) : null}
                            onVisibleTrack={(dev) => this.onShowTrackVisible(dev)}
                            onShowOthers={(dev) => this.onShowOthers(dev)}
                            onHideOthers={(dev) => this.onHideOthers(dev)}
                            onDownloadGPX={(dev) => this.onDownloadGPX(dev)}
                            onSelectTimeRange={this.onSelectTimeRange.bind(this)}
                            ref={(e) => this.setChildRef(d.id, e)}
                            statistic={statistic}
                            info={track.info}
                            track={track.track}
                            visible={track.visible}
                            tail={track.useTail}
                            onCenter={onCenter}
                            enableGPXExport={this.props.trip.enable_gpx_export}
                            device={d} />
                    )
                })}
            </div>
        )
    }
}

export default withStyles(styles)(MenuDeviceList);
