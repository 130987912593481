//"use strict";

import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const options = ["all", "7 days", "48 hrs", "24 hrs", "12 hrs"];
const noneOpt = "-- none --";

const styles = theme => {
    return {
        root: {
            zIndex: 2000,
        }

    }
};
class TimePopper extends React.Component {

    onSelect(option) {
        const { onSelect } = this.props;
        if (onSelect) {
            switch (option) {
                case noneOpt: onSelect(-1); break;
                case "all": onSelect(0); break
                case "7 days": onSelect(24 * 7); break;
                case "48 hrs": onSelect(48); break;
                case "24 hrs": onSelect(24); break;
                case "12 hrs": onSelect(12); break;
            }
        }
    }

    onClose() {
        const { onClose } = this.props;
        if (onClose) onClose();
    }

    render() {
        const { anchor, classes, withNone } = this.props;
        let open = anchor != null;
        if (!open) return null;

        return (
            <div >
                <Popper open={open} anchorEl={anchor} transition className={classes.root}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper id="menu-list-grow" >
                                <ClickAwayListener onClickAway={() => this.onClose()}>
                                    <MenuList>
                                        {withNone && (<MenuItem
                                            key={noneOpt}
                                            onClick={event => this.onSelect(noneOpt)}
                                        >
                                            {noneOpt}
                                        </MenuItem>)}
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                onClick={event => this.onSelect(option)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper></div>
        )
    }
}

export default withStyles(styles)(TimePopper);
