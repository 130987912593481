import { formatDuration } from 'gstjs2/backend';
import React from 'react';

const K_WIDTH = 30;
const K_HEIGHT = 40;
const K_WIDTH2 = 75;
const K_HEIGHT2 = 30

const LARGEPIN_FROM = 0;
const LARGEPIN_UNTIL = 8;
const SMALLPIN_LIMIT = 2;

const INDEX_COLORS = [
    "#4990E2",
    "#F44336",
    "#FF4081",
    "#7B1FA2",
    "#3F51B5",
    "#009688",
    "#8BC34A",
    "#FFEB3B",
    "#FFC107",
    "#F57C00",
    "#DCEDC8",
    "#B6B6B6",
    "#F0F4C3",
    "#00796B",
    "#E1BEE7",
    "#FF9800",
    "#ee9822"
]
function getMapIcon(color) {
    let bdata = window.btoa(`<svg width="49px" height="64px" viewBox="0 0 49 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
      <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
          <g id="map58-(1)" sketch:type="MSLayerGroup" transform="translate(1.000000, 1.000000)">
              <path d="M-0.0450631868,23.2602268 C-0.0450631868,28.4922598 1.69858516,33.3156041 4.64331593,37.2038351 L23.4492555,62.0272289 L42.2550659,37.2038351 C45.2000549,33.3156041 46.9434451,28.4923876 46.9434451,23.2602268 C46.9434451,10.4151052 36.4235797,0 23.4491264,0 C10.4748022,0 -0.0450631868,10.4151052 -0.0450631868,23.2602268 Z" id="Path" stroke="#FFFFFF" stroke-width="2" fill="${color}" sketch:type="MSShapeGroup"></path>
              <path d="M23.4491264,38.7668742 C14.7994478,38.7668742 7.78650549,31.8240247 7.78650549,23.2602268 C7.78650549,14.6966845 14.7994478,7.75332371 23.4491264,7.75332371 C32.0990632,7.75332371 39.1117473,14.6966845 39.1117473,23.2602268 C39.1117473,31.8241526 32.0991923,38.7668742 23.4491264,38.7668742 L23.4491264,38.7668742 Z" id="Shape" fill="#FFFFFF" sketch:type="MSShapeGroup"></path>
          </g>
      </g>
  </svg>`);

    return `url('data:image/svg+xml;base64,${bdata}')`;
}

function getLargeMapIcon(color) {
    let bdata = window.btoa(`<svg preserveAspectRatio="none" width="${K_WIDTH2}px" height="${K_HEIGHT2}px" viewBox="0 0 62 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
    <g id="icon_w_text" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <g id="map39-+-Rectangle-1" sketch:type="MSLayerGroup">
            <g id="map39" sketch:type="MSShapeGroup">
                <path d="M11.625,0 C5.20327734,0 0,5.03377156 0,11.2462955 L0,29.9901212 C0,36.2026451 5.20327734,41.2364167 11.625,41.2364167 L16.6658284,41.5617849 L15.153142,59.0262667 L44.1359347,41.5004739 L50.375,41.2364167 C56.7967227,41.2364167 62,36.2026451 62,29.9901212 L62,11.2462955 C62,5.03377156 56.7967227,0 50.375,0 L11.625,0 Z" id="Path-2" fill-opacity="0.6" fill="#FFFFFF"></path>
                <path d="M12.875,2 C6.86758203,2 2,6.75611069 2,12.6259542 L2,30.3358779 C2,36.2057214 6.86758203,40.9618321 12.875,40.9618321 L20.1231875,40.9618321 L14.9538879,60.0458313 L41.875,40.9618321 L49.125,40.9618321 C55.132418,40.9618321 60,36.2057214 60,30.3358779 L60,12.6259542 C60,6.75611069 55.132418,2 49.125,2 L12.875,2 Z" id="Shape" fill="${color}"></path>
            </g>
            <rect id="Rectangle-1" fill="#FFFFFF" sketch:type="MSShapeGroup" x="6" y="7" width="50" height="29" rx="7"></rect>
        </g>
    </g>
  </svg>`);
    return `url('data:image/svg+xml;base64,${bdata}')`;
}
export function getColor(idx) {
    return INDEX_COLORS[idx % INDEX_COLORS.length];
}

export function colorFromDevice(d) {
    let num = 0;
    for (let c in d.id) {
        num = num + d.id.charCodeAt(c)
    }
    return getColor(num);
}

const deviceMarkerStyle = {
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: - K_HEIGHT,
    marginTop: - K_HEIGHT,
    marginLeft: - K_WIDTH / 2,

    backgroundImage: getMapIcon("#4990E2"),
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    textAlign: 'center',
    color: '#3f51b5',
    padding: 0,
    fontSize: 13,
    fontWeight: 'bold',
    zIndex: 1000
};
const deviceMarkerStyleLarge = {
    width: K_WIDTH2,
    height: K_HEIGHT2,
    left: -K_WIDTH2 / 2,
    top: - K_HEIGHT2,
    marginTop: - K_HEIGHT2,
    marginLeft: - K_WIDTH2 / 4,

    backgroundImage: getMapIcon("#4990E2"),
    backgroundRepeat: 'no-repeat',
    backgroundSize: '75px 30px',
    textAlign: 'center',
    color: '#3f51b5',
    padding: 0,
    fontSize: 13,
    fontWeight: 'bold',
    zIndex: 1000
};

export class DeviceMarker extends React.PureComponent {

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            deviceTrack: props.deviceTrack,
            hoverhint: false,
        };
    }

    showInfo() {
        const { onInfo, device } = this.props;
        const { deviceTrack } = this.state;

        deviceTrack.info = !deviceTrack.info;
        this.setState({ deviceTrack });
        if (onInfo) onInfo(device, deviceTrack.info)
    }

    toHHMMSS(sec_num) {
        return formatDuration(sec_num);
    }

    toTemperature(unit, temp) {
        if (unit === "fahrenheit") {
            let val = (temp * 1.8 + 32).toFixed(0);
            return `${val} °F`;
        }
        return `${temp} °C`;
    }

    toPressure(unit, p) {
        if (unit === "inhg") {
            let val = (p * 0.02953).toFixed(3);
            return `${val} inHG`;
        }
        return `${p} mbar`;
    }

    toDistance(unit, distmeter) {
        if (unit === "km") {
            var km = distmeter / 1000.0;
            km = km.toFixed(3);
            return `${km}km`;
        }
        if (unit === "mile") {
            var ml = distmeter / 1609.344;
            ml = ml.toFixed(3);
            return `${ml}miles`;
        }
        if (unit === "sm") {
            var sm = distmeter / 1852.0;
            sm = sm.toFixed(3);
            return `${sm}sm`;
        }
        return `${distmeter}m`;
    }

    toSpeed(unit, speedkmh, ignoreunit) {
        if (unit === "mph") {
            var mph = (speedkmh * 0.621371).toFixed(2);
            return ignoreunit ? `${mph}` : `${mph} mp/h`;
        }
        if (unit === "kn") {
            var kn = (speedkmh * 0.539957).toFixed(2);
            return ignoreunit ? `${kn}` : `${kn} kn`;
        }
        speedkmh = (speedkmh).toFixed(2); // runden
        return ignoreunit ? `${speedkmh}` : `${speedkmh} km/h`;
    }

    needsLargePin(text) {
        //return true; // always use large pin
        //return (text.length > LARGEPIN_FROM && text.length <= LARGEPIN_UNTIL);
        return text.length > SMALLPIN_LIMIT;
    }

    deviceLabel(outerlabel, device) {
        let result = outerlabel;
        if (device.name) {
            result = device.name;

        }
        if (result.length > LARGEPIN_UNTIL) {
            result = result.substring(0, LARGEPIN_UNTIL - 1) + "..";
        }

        return result;
    }

    onHover(t) {
        this.setState({ hoverhint: t });
    }

    render() {
        let { device, index, trip, event, lat, lng } = this.props;
        const { deviceTrack } = this.state;
        let { statistic } = deviceTrack;
        const latestPosition = event.position;
        const lastSent = event.sent;
        const speedunit = trip.unitSpeed;
        const temperatureUnit = trip.unitTemperature;
        const pressureUnit = trip.unitAirpressure;
        const distanceunit = trip.unitDistance;
        const markerProps = trip.markerProps;

        let text = this.deviceLabel(this.props.text, device);

        if (statistic.latestEvent.sent.getTime() != event.sent.getTime()) {
            // if the displayed event is not the latest one, we do not display stats
            statistic = null;
        }
        let cssNames = "map-marker-hint";
        if (deviceTrack.info || this.state.hoverhint) cssNames = cssNames + " map-marker-hint-show";
        let lbl = {
            paddingRight: "5px",
            float: "left",
            textAlign: 'left',
            display: 'inline-block'
        }
        let val = {
            textAlign: 'left',
            whiteSpace: "nowrap",
            display: 'inline-block'
        }
        let wrapval = {
            textAlign: 'left',
            display: 'inline-block'
        }
        let row = {
            paddingTop: "5px"
        }

        //let idx = index % 10;
        let bcolor = colorFromDevice(device);

        let markerStyle = { ...deviceMarkerStyle };
        let labelStyle = { paddingTop: "6px", cursor: "pointer" };
        if (this.needsLargePin(text)) {
            markerStyle = { ...deviceMarkerStyleLarge };
            labelStyle = { paddingTop: "2px", cursor: "pointer" };
            markerStyle.backgroundImage = getLargeMapIcon(bcolor);
        }
        else {
            markerStyle.backgroundImage = getMapIcon(bcolor);
        }

        let showPosition = markerProps.positionInfo ? "show" : "hide";
        let showImei = markerProps.imeiInfo ? "show" : "hide"
        let showName = markerProps.devicename ? "show" : "hide"
        let showAltitude = markerProps.altitudeInfo ? "show" : "hide"
        let showAGL = markerProps.aglInfo ? "show" : "hide";
        let showSpeed = markerProps.speedInfo ? "show" : "hide";
        let showVSpeed = markerProps.vspeedInfo ? "show" : "hide";
        let showPos6D = markerProps.position6d ? "show" : "hide";
        let showTemperature = markerProps.temperature ? "show" : "hide";
        let showPressure = markerProps.airpressure ? "show" : "hide";


        let altValue = latestPosition ? latestPosition.alt : "";
        let aglValue = latestPosition ? Math.max(latestPosition.alt - latestPosition.height, 0) : "";
        let speedValue = latestPosition ? this.toSpeed(speedunit, latestPosition.speedkmh, false) : "";
        let vspeedValue = latestPosition ? latestPosition.verticalspeedms.toFixed(0) : "";
        let pos6dValue = latestPosition ? latestPosition.pos6d : "";
        let tempValue = event.environment ? this.toTemperature(temperatureUnit, event.environment.temperature) : "";
        let pressureValue = event.environment ? this.toPressure(pressureUnit, event.environment.airpressure) : "";

        let started = "";
        let distance = "";
        let elapsed = "";
        let altmaxmin = "";
        let aglmaxmin = "";
        let speedmaxmin = "";
        let vspeedmaxmin = "";
        let lastsignal = new Date(event.sent).toLocaleString();
        let lastmessage = "";
        let imei = device.imei || device.id;
        let lastmsgTS = null;

        if (statistic) {
            altValue = altValue
            altmaxmin = `${statistic.maxAltitudeMeter} / ${statistic.minAltitudeMeter}`
            aglmaxmin = `${statistic.maxAGLMeter} / ${statistic.minAGLMeter}`
            let tdiff = new Date(lastSent).getTime() - new Date(statistic.firstEvent.sent).getTime();
            if (tdiff) tdiff = parseInt(tdiff / 1000, 10);
            started = new Date(statistic.firstEvent.sent).toLocaleString();
            lastsignal = new Date(lastSent).toLocaleString();
            elapsed = this.toHHMMSS(tdiff);
            let avg = this.toSpeed(speedunit, statistic.averageSpeedKmh, true);
            let smin = this.toSpeed(speedunit, statistic.minSpeedKmh, true);
            let smax = this.toSpeed(speedunit, statistic.maxSpeedKmh, true);
            speedmaxmin = `${smin}/${smax}/${avg}`
            let vavg = statistic.averageVSpeedMs.toFixed(0);
            let vsmin = statistic.minVSpeedMs.toFixed(0);
            let vsmax = statistic.maxVSpeedMs.toFixed(0);
            vspeedmaxmin = `${vsmin}/${vsmax}/${vavg}`

            let dist = statistic.distanceMeter.toFixed(2);
            distance = this.toDistance(distanceunit, dist);
            lastmessage = statistic.lastMessage;
            if (lastmessage) {
                let dt = new Date(statistic.lastMessageTime);
                if (dt.getFullYear() > 2000) {
                    let ds = dt.toLocaleString();
                    lastmsgTS = (<span>{ds}<br /></span>);
                }
            }
        }
        let showTime = markerProps.timeInfo && started ? "show" : "hide"
        let showLastSignal = markerProps.lastSignal && lastsignal ? "show" : "hide";
        let showLastmessage = markerProps.lastMessage && lastmessage ? "show" : "hide";
        let showDistance = markerProps.distance && distance ? "show" : "hide";
        let showAltitudeMaxMin = markerProps.altitudeInfo && altmaxmin != "" ? "show" : "hide";
        let showAGLMaxMin = markerProps.aglInfo && aglmaxmin != "" ? "show" : "hide";
        let showSpeedMaxMin = markerProps.speedInfo && speedmaxmin != "" ? "show" : "hide";
        let showVSpeedMaxMin = markerProps.vspeedInfo && vspeedmaxmin != "" ? "show" : "hide";

        let st = { border: "1px solid " + bcolor } //, animation: "fadein 0.8s" };
        if (this.state.hoverhint) st.zIndex = 20000;
        let infoelement = (
            <div className={cssNames} onClick={this.showInfo.bind(this)} style={st} >
                <div style={{ borderBottom: "1px solid white", display: "flex", flexDirection: "row", justifyContent: "center", ...row }} className={showName}>
                    <span style={val}>{device.name}</span>
                </div>
                <div className={showLastmessage} style={{ borderBottom: "1px solid white" }}>
                    <span style={wrapval}>{lastmsgTS}{lastmessage}</span>
                </div>
                <div className={showImei} style={row}>
                    <span style={lbl}>IMEI:</span>
                    <span style={val}>{imei}</span>
                </div>
                <div className={showTime} style={row}>
                    <span style={lbl}>Started:</span>
                    <span style={val}>{started}</span>
                </div>
                <div className={showLastSignal} >
                    <span style={lbl}>Latest Pos.:</span>
                    <span style={val}>{lastsignal}</span>
                </div>
                <div className={showTime} >
                    <span style={lbl}>Elapsed:</span>
                    <span style={val}>{elapsed}</span>
                </div>
                <div className={showPosition} style={row}>
                    <span style={lbl}>Lat:</span>
                    <span style={val}>{latestPosition.lat.toFixed(5)}</span>
                </div>
                <div className={showPosition}>
                    <span style={lbl}>Lng:</span>
                    <span style={val}>{latestPosition.lng.toFixed(5)}</span>
                </div>
                <div className={showAltitude} style={row}>
                    <span style={lbl}>Alt:</span>
                    <span style={val}>{altValue}m</span>
                </div>
                <div className={showAltitudeMaxMin} >
                    <span style={lbl}>Max/Min:</span>
                    <span style={val}>{altmaxmin}</span>
                </div>
                <div className={showAGL} style={row}>
                    <span style={lbl}>AGL:</span>
                    <span style={val}>{aglValue}m</span>
                </div>
                <div className={showAGLMaxMin} >
                    <span style={lbl}>Max/Min:</span>
                    <span style={val}>{aglmaxmin}</span>
                </div>
                <div className={showDistance} style={row}>
                    <span style={lbl}>Distance:</span>
                    <span style={val}>{distance}</span>
                </div>
                <div className={showSpeed} style={row}>
                    <span style={lbl}>Speed:</span>
                    <span style={val}>{speedValue}</span>
                </div>
                <div className={showSpeedMaxMin}>
                    <span style={lbl}>Min/Max/Avg:</span>
                    <span style={val}>{speedmaxmin}</span>
                </div>
                <div className={showVSpeed} style={row}>
                    <span style={lbl}>VSpeed:</span>
                    <span style={val}>{vspeedValue} m/s</span>
                </div>
                <div className={showVSpeedMaxMin}>
                    <span style={lbl}>Min/Max/Avg:</span>
                    <span style={val}>{vspeedmaxmin}</span>
                </div>
                <div className={showPos6D} style={row}>
                    <span style={lbl}>Pos6D:</span>
                    <span style={val}>{pos6dValue}</span>
                </div>
                <div className={showTemperature} style={row}>
                    <span style={lbl}>Temp.:</span>
                    <span style={val}>{tempValue}</span>
                </div>
                <div className={showPressure} style={row}>
                    <span style={lbl}>Pressure:</span>
                    <span style={val}>{pressureValue}</span>
                </div>
            </div>
        );

        return (
            <div style={markerStyle}
                onClick={this.showInfo.bind(this)}
            >
                <div style={labelStyle}
                    onMouseEnter={() => this.onHover(true)}
                    onMouseLeave={() => this.onHover(false)}
                >{text}</div>
                {infoelement}
            </div>
        );
    }
}
