import SvgIcon from '@material-ui/core/SvgIcon';
import EMailIcon from '@material-ui/icons/Email';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import MessageIcon from '@material-ui/icons/Message';
import PublicIcon from '@material-ui/icons/Public';
import SMSIcon from '@material-ui/icons/Sms';
import React from 'react';
import alive1g from './img/alive.png';
import flymaster3g from './img/flymaster3g.png';
import inreach_gspmap66i from './img/inreach_gpsmap66i.png';
import inreach_gspmap67i from './img/inreach_gpsmap67i.png';
import inreach_gspmap86i from './img/inreach_gpsmap86i.png';
import inreach_messenger from './img/inreach_messenger.png';
import inreach_mini from './img/inreach_mini.png';
import inreach_mobile from './img/inreach_mobile.png';
import inreach_montana from './img/inreach_montana.png';
import inreach_se from './img/inreach_se.png';
import inreach_tread from './img/inreach_tread.png';
import inreach_xplorer from './img/inreach_xplorer.png';
import inreach_xplorerplus from './img/inreach_xplorerplus.png';
import meitrack from './img/meitrack.png';
import meitrackP88 from './img/meitrackP88.png';
import meitrackP99 from './img/meitrackP99.png';
import meitrackT355 from './img/meitrackT355.png';
import { default as sole1g, default as sole2g } from './img/sole.png';
import spot3 from './img/spot3.png';
import unknownImage from './img/unknown_device.png';

// slack icon from here https://iconify.design/icon-sets/mdi/slack.html with some modifications
const slackIcon = (<svg aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5z" fill="#626262" /></svg>);

export const NotifyIcons = {
    email: {
        id: "email",
        icon: (<EMailIcon />),
        name: "EMail"
    },
    sms: {
        id: "sms",
        icon: (<SMSIcon />),
        name: "SMS"
    },
    delorme: {
        id: "local_hospital",
        icon: (<LocalHospitalIcon />),
        name: "Rescue device"
    },
    webhook: {
        id: "public",
        icon: (<PublicIcon />),
        name: "Webhook"
    },
    telegram: {
        id: "message",
        icon: (<MessageIcon />),
        name: "Telegram"
    },
    slack: {
        id: "slack",
        icon: (<SvgIcon>{slackIcon}</SvgIcon>),
        name: "Slack"
    }
}


export const DevTypes = {
    INREACH_EXPLORER: inreach_xplorer,
    INREACH_EXPLORERPLUS: inreach_xplorerplus,
    INREACH_SE: inreach_se,
    INREACH_MOBILE: inreach_mobile,
    INREACH_MINI: inreach_mini,
    INREACH_MINI2: inreach_mini,
    INREACH_MONTANA: inreach_montana,
    INREACH_GPSMAP66i: inreach_gspmap66i,
    INREACH_GPSMAP67i: inreach_gspmap67i,
    INREACH_GPSMAP86i: inreach_gspmap86i,
    INREACH_TREAD: inreach_tread,
    INREACH_MESSENGER: inreach_messenger,
    MEITRACK: meitrack,
    MEITRACK_T355: meitrackT355,
    MEITRACK_P99: meitrackP99,
    MEITRACK_P88: meitrackP88,
    SPOT3: spot3,
    FLYMASTER3G: flymaster3g,
    ALIVE1G: alive1g,
    SOLE1G: sole1g,
    SOLE2G: sole2g,
    UNKNOWN: unknownImage,
    "": unknownImage,

};
