"use strict";

import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import { formatLocalDate } from 'gstjs2/backend';

const styles = theme => {

    return {

        root: {
            overflowY: "hidden",
        },
        sliderControl: {
            paddingTop: 25,
            paddingLeft: 50,
            paddingRight: 50
        },
        sliderLabels: {
            marginTop: -3,
            paddingLeft: 70,
            paddingRight: 70,
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 20,
                flexWrap: "wrap",
                justifyContent: "flex-start",
                flexDirection: "column",
                paddingTop: 0,
                paddingBottom: 10
            }
        },
        startlabel: {
            display: "inline-block",
            marginLeft: -25,
            paddingTop: -5,
            fontSize: "0.8rem",
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
                paddingTop: 0,
                fontSize: "0.7rem",
            }
        },
        endlabel: {
            display: "inline-block",
            marginRight: -25,
            paddingTop: -5,
            fontSize: "0.8rem",
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
                paddingTop: 0,
                marginTop: 5,
                fontSize: "0.7rem",
            }
        },
        valueLabel: {
            display: "flex",
            fontSize: "0.8rem",
            marginTop: 10,
            marginBottom: -20,
            justifyContent: "center"
        },
        slider: {
            width: "100%"
        },
        controlbar: {
            display: "flex",
            justifyContent: "center",
            fontSize: "0.8rem",
            marginTop: -40,
            paddingBottom: 10,
            alignContent: "flex-end",
            [theme.breakpoints.down('xs')]: {
                flexDirection: "column",
                //width: 120,
                marginTop: -100,
                marginLeft: 230,
                flexWrap: "wrap",
                justifyContent: "right",
                paddingBottom: 20
            }
        },
        IconBar: {
            display: "flex",
            justifyContent: "space-evenly"
        },
        controlIcon: {
            display: "flex",
        },
        datecontainer: {
            display: 'flex',
            flexWrap: 'wrap',
            fontSize: "0.8rem",
            [theme.breakpoints.down('xs')]: {
                fontSize: "0.7rem"
            }
        },
        datetextField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
                marginRight: 0,
                width: 180,
            }
        },
        datetext: {
            fontSize: "0.8rem"
        },

        speedtext: {
            fontSize: "0.8rem",
            textAlign: 'center',
        },

        formControl: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            fontSize: "0.8rem",
        },

        speedSelection: {
            width: "5rem"
        },

        playbw: {
            "&:hover": {
                color: "red"
            }
        }

    }
};

// update in millisecs when playback is started
const baseInc = 100;

class TimelineControl extends React.Component {
    constructor(props) {
        super(props);

        let { begin, end } = this._getDates(props);
        let now = props.initTS;

        this.state = {
            begin, end,
            value: now,
            playing: false,
            playback: false,
            inc: baseInc,
            sliderValue: now.getTime(),
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.minDate != this.props.minDate ||
            prevProps.maxDate != this.props.maxDate) {
            this.setState({ ...this._getDates(this.props) })
        }
    }

    _getDates(props) {
        let end = new Date(props.trip.end);
        let now = new Date();
        if (end.getFullYear() < 2000 || now.getTime() < end.getTime()) {
            end = now;
        }
        if (props.maxDate) end = props.maxDate;
        let begin = new Date(props.trip.start);
        if (props.minDate) begin = props.minDate;
        return { begin, end }
    }

    updateValue(value) {
        const { onSetTimestamp } = this.props;

        const { begin, end } = this.state;
        let t = new Date(value);
        if (value > end.getTime()) {
            t = end;
            this.onPause()
        }
        if (value < begin.getTime()) {
            t = begin;
            this.onPause()
        }
        this.setState({ value: t, sliderValue: value });
        if (onSetTimestamp) onSetTimestamp(t);
    }

    onValueChange(evt, value) {
        this.updateValue(value);
    }

    onPlay(evt, reverse) {
        evt.preventDefault()
        if (!this.timer) {
            this.timer = window.setInterval(() => {
                const { sliderValue, inc } = this.state;
                const delta = reverse ? -1 * inc : inc;
                this.updateValue(sliderValue + delta);
            }, baseInc);

            this.setState({ playing: true });
        }
    }

    onPause(evt) {
        evt.preventDefault()
        if (this.timer) window.clearTimeout(this.timer);
        this.timer = null;
        this.setState({ playing: false });
    }

    onChangeDate(fld, val) {
        if (!val) {
            if (fld == "begin") {
                val = new Date(this.props.trip.start)
            } else {
                let end = new Date(this.props.trip.end);
                let now = new Date();
                if (end.getFullYear() < 2000 || now.getTime() < end.getTime()) {
                    end = now;
                }
                val = end;
            }
        }
        val = new Date(val)
        let value = this.state.value;
        if (fld == "begin") {
            if (this.state.value.getTime() < val.getTime()) {
                value = val;
            }
        } else if (fld == "end") {
            if (this.state.value.getTime() > val.getTime()) {
                value = val;
            }
        }

        this.setState({ [fld]: val, value, sliderValue: value.getTime() });
    }

    render() {
        const { classes, trip } = this.props;
        const { begin, end, value, sliderValue } = this.state;

        let beginStr = formatLocalDate(begin, true);
        let endStr = formatLocalDate(end, true);
        let valueStr = value.toLocaleString(undefined, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            fractionalSecondDigits: 2
        });


        let min = begin.getTime();
        let max = end.getTime();

        let playIcon = (
            <div className={classes.controlIcon}>
                <IconButton
                    onClick={(evt) => this.onPlay(evt, false)}
                    onTouchEnd={(evt) => this.onPlay(evt, false)}
                    size="small"
                    color="primary"
                    aria-label="Start"
                    title="Start"
                    disabled={this.state.playing ? true : false}>

                    <PlayArrowIcon fontSize="small" />
                </IconButton>
            </div>
        )
        let pauseIcon = (
            <div className={classes.controlIcon}>
                <IconButton
                    onClick={(evt) => this.onPause(evt)}
                    onTouchEnd={(evt) => this.onPause(evt)}
                    size="small"
                    color="primary"
                    aria-label="Pause"
                    title="Pause"
                    disabled={this.state.playing ? false : true}>
                    <PauseIcon fontSize="small" />
                </IconButton>
            </div>
        )

        let playbackIcon = (
            <div className={classes.controlIcon}>
                <IconButton
                    size="small"
                    title="Play backward"
                    color="primary"
                    disabled={this.state.playing ? true : false}
                    onClick={(evt) => this.onPlay(evt, true)}
                    onTouchEnd={(evt) => this.onPlay(evt, true)}
                >
                    <PlayArrowIcon fontSize="small" style={{ transform: "rotate(180deg)" }} />
                </IconButton></div>)



        return (
            <div className={classes.root}>
                <div className={classes.valueLabel}><span>{valueStr}</span></div>
                <div className={classes.sliderControl}>
                    <Slider
                        classes={{
                            root: classes.slider,
                        }}
                        step={0.01}
                        min={min}
                        max={max}
                        value={sliderValue}
                        aria-labelledby="label"
                        onChange={(evt, v) => this.onValueChange(evt, v)}
                    />
                </div>
                <div className={classes.sliderLabels}>
                    <div className={classes.startlabel}>
                        <form className={classes.datecontainer} noValidate>
                            <TextField
                                onChange={(evt) => { this.onChangeDate("begin", evt.target.value) }}
                                id="from"
                                label="Start"
                                type="datetime-local"
                                value={beginStr}
                                className={classes.datetextField}
                                InputProps={{
                                    classes: {
                                        input: classes.datetext
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </form>
                    </div>
                    <div className={classes.endlabel}>
                        <form className={classes.datecontainer} noValidate>
                            <TextField
                                onChange={(evt) => { this.onChangeDate("end", evt.target.value) }}
                                id="to"
                                label="End"
                                type="datetime-local"
                                value={endStr}
                                className={classes.datetextField}
                                InputProps={{
                                    classes: {
                                        input: classes.datetext
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </form>

                    </div>
                </div>
                <div className={classes.controlbar}>
                    <div className={classes.IconBar}>
                        {playbackIcon}
                        {pauseIcon}
                        {playIcon}
                    </div>
                    <FormControl className={classes.speedSelection + " " + classes.formControl}>
                        <InputLabel htmlFor="playbackspeed">Speed</InputLabel>
                        <NativeSelect
                            value={this.state.inc / baseInc}
                            style={{ fontSize: '0.8rem' }}
                            onChange={(evt) => this.setState({ inc: baseInc * evt.target.value })}
                            inputProps={{
                                name: 'playbackspeedunit',
                                id: 'playbackspeed',

                            }}
                        >
                            <option className={classes.speedtext} value={0.1}>0.1 x</option>
                            <option className={classes.speedtext} value={0.5}>0.5 x</option>
                            <option className={classes.speedtext} value={1}>1.0 x</option>
                            <option className={classes.speedtext} value={1.5}>1.5 x</option>
                            <option className={classes.speedtext} value={5}>5.0 x</option>
                            <option className={classes.speedtext} value={10}>10.0x</option>

                        </NativeSelect>
                    </FormControl>
                </div>
            </div>
        )
    }
}



export default withStyles(styles)(TimelineControl);
