import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Input from '@material-ui/core/Input';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
} from "@material-ui/pickers";
import InsertLink from '@material-ui/icons/InsertLink';
import IconButton from '@material-ui/core/IconButton';

import { formatLocalDate } from 'gstjs2/backend';

const styles = theme => {
    return {
        root: {
            zIndex: 2000,
        },
        urltext: {
            fontSize: "0.8rem",
            overflowX: "hidden"
        },
        infotext: {
            fontSize: "0.75rem"
        }
    }
};

class ExportDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            min: props.min,
            max: props.max
        }
        this.urltext = React.createRef();
    }

    componentDidUpdate(prevProps) {
        let oldmin = prevProps.min.getTime();
        let oldmax = prevProps.max.getTime();
        let newstate = {};
        if (oldmin != this.props.min.getTime()) newstate.min = this.props.min;
        if (oldmax != this.props.max.getTime()) newstate.max = this.props.max;
        if (newstate.length)
            this.setState({ ...newstate });
    }

    onClose() {
        let { onClose } = this.props;
        if (onClose) onClose()
    }
    onExport() {
        let { onExport } = this.props;
        if (onExport) onExport(this.state.min, this.state.max, this.props.device)
    }

    onChangeMin(dt) {
        this.setState({ min: dt.toDate() })
    }
    onChangeMax(dt) {
        this.setState({ max: dt.toDate() })
    }

    onCopyToClipboard(text) {
        this.urltext.current.select();
        this.urltext.current.focus();
        try {
            document.execCommand('copy');
        } catch (err) {
            console.error('unable to copy', err);
        }
    }

    render() {
        const { device, trip, open, classes } = this.props;
        const { min, max } = this.state;

        if (!open) return null;

        let devname = device.name || device.id;
        let devid = "";
        if (device.id) devid = device.id;

        let descr = `Export the tracking points from  "${devname}" between the two dates.`
        let minstr = formatLocalDate(min, true, true);
        let maxstr = formatLocalDate(max, true, true);

        let fromu = encodeURIComponent(min.toISOString());
        let tou = encodeURIComponent(max.toISOString());

        let url = location.origin + "/tripview/api/v1/trip/" + trip.id + "/gpx?from=" + fromu + "&until=" + tou + "&name=" + encodeURIComponent(devname) + "&imei=" + devid;

        return (
            <Dialog open={open} onClose={this.onClose.bind(this)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Export GPX</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {descr}
                    </DialogContentText>
                    <DateTimePicker
                        onChange={(d) => this.onChangeMin(d)}
                        value={min}
                        ampm={false}
                        clearable={false}
                        disableFuture
                        required
                        format="DD.MM.YYYY HH:mm"
                        showTodayButton
                        label="From"
                    />
                    <DateTimePicker
                        onChange={(d) => this.onChangeMax(d)}
                        value={max}
                        ampm={false}
                        clearable={false}
                        disableFuture
                        required
                        format="DD.MM.YYYY HH:mm"
                        showTodayButton
                        label="To"
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Input
                            onClick={() =>  this.urltext.current.select()}
                            inputRef={this.urltext}
                            value={url}
                            className={classes.urltext}
                            style={{ flex: 1 }}
                            inputProps={{
                                'aria-label': 'URL',
                                readOnly: true,
                            }}
                        />
                        <IconButton onClick={() => this.onCopyToClipboard(url)} title="Copy Link to Clipboard">
                            <InsertLink />
                        </IconButton>
                    </div>
                    <div className={classes.infotext}>
                        You can use <a target="_new" href="https://umap.openstreetmap.de/en/map/new">UMap</a>&nbsp;
                        or <a target="_new" href="https://www.google.com/maps/d/">Google my Maps</a> to
                        create a new map. Import the given URL as GPX data format. Or click <b>Export</b> to
                        download the GPX dataset and import the data in your preferred application.
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onExport.bind(this)} color="primary">
                        Export
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles)(ExportDialog);
