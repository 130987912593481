//import fetch from 'isomorphic-fetch'

import { ActionInit } from './actions';

const shapeColors = {
  safe: "#006600",
  rest: "#0000cc",
  danger: "#cc0000"
};

const shapeColorsHigh = {
  safe: "#00e600",
  rest: "#6666ff",
  danger: "#ff6666"
};

let token = null;
let app = null;

function fetchInit(method, token, body, ct) {
  if (!ct) ct = "application/json";
  let h = new Headers({
    "Content-Type": ct,
    "Authorization": token,
  });
  let res = {
    method: method,
    headers: h,
    cache: 'default'
  };
  if (body) {
    if (ct == "application/json")
      res.body = JSON.stringify(body);
    else
      res.body = body;
  }
  return res;
}

function check(rsp) {
  if (rsp.ok) {
    return rsp.json();
  }
  if (rsp.status == 404) {
    return Promise.resolve(null);
  }
  if (rsp.status == 401) {
    if (app)
      location.href = `/?page=${app}`;
    else
      location.href = "/";
  }
  return rsp.json().then((e) => {
    return Promise.reject({ status: rsp.status, text: e.error });
  });
}

export function logRecord(r) {
  fetch("/logrecord.json", fetchInit("POST", "", r));
}

export function getParameters() {
  var query_string = {};
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    // If first entry with this name
    if (typeof query_string[pair[0]] === "undefined") {
      query_string[pair[0]] = decodeURIComponent(pair[1]);
      // If second entry with this name
    } else if (typeof query_string[pair[0]] === "string") {
      var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
      query_string[pair[0]] = arr;
      // If third or later entry with this name
    } else {
      query_string[pair[0]].push(decodeURIComponent(pair[1]));
    }
  }
  return query_string;
}

export function getParameter(key) {
  return getParameters()[key];
}

export function Init(usr, tok, myapp, selectedorg) {
  app = myapp;
  return User.me(tok)
    .then(ud => {
      token = tok;
      ActionInit(tok);
      logRecord({ "loginresult": { "userid": ud.id, "username": ud.name, "userorg": ud.organization, "provideruser": ud.user, "uiapp": myapp } });
      let prs = [User.organization({ id: ud.organization })];
      if (selectedorg) {
        prs.push(User.organization({ id: selectedorg.id }))
      }
      return Promise.all(prs).then((values) => {
        if (values.length > 1) {
          return Promise.resolve({ user: usr, me: ud, org: values[1], userorg: values[0] })
        }
        return Promise.resolve({ user: usr, me: ud, org: values[0], userorg: values[0] })
      });
    })
}

export function Anonymous() {
  token = "GUEST";
  ActionInit(token);
}

export function identity(d) { return Promise.resolve(d); }

export function parseDate(dt) {
  if (!dt) return null;

  let res = new Date(dt);
  if (res.getFullYear() === 1) {
    return null;
  }
  return date2UTC(res);
}

export function date2Stamp(d) {
  let dd = asUTC(d)
  let dt = dd.getUTCDate() + "-" + dd.getUTCMonth() + "-" + dd.getUTCFullYear();
  return dt;
}

export function stamp2Date(st) {
  let parts = st.split("-");
  return new Date(parts[2], parts[1], parts[0]);
}

export function formatDate(dat, withtime) {
  let dt = asUTC(dat);
  let d = ("00" + dt.getUTCDate()).slice(-2);
  let m = ("00" + (dt.getUTCMonth() + 1)).slice(-2);
  let y = dt.getUTCFullYear();
  let hh = ("00" + dt.getHours()).slice(-2);
  let mm = ("00" + dt.getMinutes()).slice(-2);
  let ss = ("00" + dt.getSeconds()).slice(-2);

  let fmt = `${y}-${m}-${d}`;

  if (withtime) {
    fmt = `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
  }

  //console.log("dt = ", dt, "dat=", dat, "fmt = ",fmt);
  return fmt;
}

export function formatLocalDate(dat, withtime, asInputFormat) {
  let dt = dat;
  let d = ("00" + dt.getDate()).slice(-2);
  let m = ("00" + (dt.getMonth() + 1)).slice(-2);
  let y = dt.getFullYear();
  let hh = ("00" + dt.getHours()).slice(-2);
  let mm = ("00" + dt.getMinutes()).slice(-2);
  let ss = ("00" + dt.getSeconds()).slice(-2);

  let fmt = `${y}-${m}-${d}`;

  if (withtime) {
    fmt = `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
  }
  if (asInputFormat) {
    fmt = `${y}-${m}-${d}T${hh}:${mm}:${ss}`;
  }

  //console.log("dt = ", dt, "dat=", dat, "fmt = ",fmt);
  return fmt;
}

export function date2UTC(d) {
  return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
}

export function addDays(dt, d) {
  return new Date(dt.getTime() + d * 24 * 3600 * 1000);
}

export function asUTC(d) {
  return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
}

export function isBetween(fromutc, tmutc, toutc) {
  let t = tmutc.getTime();
  let f = fromutc.getTime();
  let to = toutc.getTime();

  return t >= f && t <= to;
}

export function dateLimits(from, days) {
  let f = from;
  let t = addDays(from, days);
  return { from: f, to: t };
}

export function formatDuration(sec_num) {
  var days = Math.floor(sec_num / 24 / 3600);
  var hours = Math.floor((sec_num - (days * 24 * 3600)) / 3600);
  var minutes = Math.floor((sec_num - (days * 24 * 3600) - (hours * 3600)) / 60);
  var seconds = sec_num - (days * 24 * 3600) - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  let res = hours + ':' + minutes + ':' + seconds;
  if (days > 0) {
    return days + "d " + res;
  }
  return res;
}

// Helper functions to patch results from server

function _deviceTS(d) {
  d.lastchanged = new Date(d.changed);
  return Promise.resolve(d);
}
function _devicesTS(devs) {
  return Promise.all(devs.map(_deviceTS));
}

function __rateTS(r) {
  r.validto = date2UTC(new Date(r.validto));
  r.validfrom = date2UTC(new Date(r.validfrom));
  return r;
}

function _rateTS(rates) {
  return Promise.all(rates.map(__rateTS));
}

function _serviceTS(s) {
  return Promise.resolve(__serviceTS(s));
}
function _servicesTS(srvs) {
  return Promise.all(srvs.map(_serviceTS));
}

function __serviceTS(s) {
  if (!s) return s;
  s.validto = date2UTC(new Date(s.validto));
  s.validfrom = date2UTC(new Date(s.validfrom));
  let rts = s.rates || [];
  for (let r of rts) {
    __rateTS(r);
  }
  return s;
}
function _bookingTS(b) {
  if (b.service) __serviceTS(b.service);
  if (b.rate) __rateTS(b.rate);
  b.to = date2UTC(new Date(b.to));
  b.from = date2UTC(new Date(b.from));
  return Promise.resolve(b);
}
function _bookingsTS(bks) {
  return Promise.all(bks.map(_bookingTS));
}

function _paymentTS(p) {
  p.payment.request = new Date(p.payment.request);
  p.payment.done = new Date(p.payment.done);
  return Promise.resolve(p);
}
function _paymentsTS(ps) {
  return Promise.all(ps.map(_paymentTS));
}
function _providerTS(s) {
  if (!s) return s;
  if (!s.openingtimes) return s;
  s.openingtimes.map(ot => {
    ot.from = date2UTC(new Date(ot.from));
    ot.to = date2UTC(new Date(ot.to));
  });
  return s;
}
function _providersTS(ps) {
  return Promise.all(ps.map(_providerTS));
}

function _couponTS(db) {
  db.coupon.from = new Date(db.coupon.from);
  db.coupon.to = new Date(db.coupon.to);
  return Promise.resolve(db);
}

export function toFloat(value) {
  return parseFloat(value.replace(',', '.'), 10);
}

const isnumber = /^\d+(\.\d{0,2})?$/;
export function isPriceNumber(val) {
  return val.match(isnumber);
}

class UserApi {
  me(tok) {
    return fetch('/users/api/v1/me', fetchInit("GET", tok)).then(check);
  }
  newToken() {
    return fetch('/users/api/v1/me/newtoken', fetchInit("GET", token)).then(check);
  }
  newTokenForUser(usr) {
    return fetch(`/users/api/v1/user/${usr.id}/newtoken`, fetchInit("GET", token)).then(check);
  }
  organization(org) {
    return fetch(`/users/api/v1/org/${org.id}`, fetchInit("GET", token)).then(check);
  }
  organizations() {
    return fetch('/users/api/v1/org', fetchInit("GET", token)).then(check);
  }
  createOrganization(org) {
    return fetch('/users/api/v1/org', fetchInit("POST", token, org)).then(check);
  }
  saveOrganization(org) {
    return fetch(`/users/api/v1/org/${org.id}`, fetchInit("PUT", token, org)).then(check);
  }
  deleteOrganization(org) {
    return fetch(`/users/api/v1/org/${org.id}`, fetchInit("DELETE", token)).then(check);
  }
  assignRates(org, rates) {
    return fetch(`/users/api/v1/org/${org.id}/assign`, fetchInit("POST", token, rates)).then(check);
  }
  saveDocuments(org, docs) {
    return fetch(`/users/api/v1/org/${org.id}/documents`, fetchInit("POST", token, docs)).then(check);
  }
  getDocuments(org) {
    return fetch(`/users/api/v1/org/${org.id}/documents`, fetchInit("GET", token)).then(check);
  }
  createOffice(org, office) {
    return fetch(`/users/api/v1/org/${org.id}/office`, fetchInit("POST", token, office)).then(check);
  }
  saveOffice(org, office) {
    return fetch(`/users/api/v1/org/${org.id}/office/${office.id}`, fetchInit("PUT", token, office)).then(check);
  }
  office(org, officeid) {
    return fetch(`/users/api/v1/org/${org.id}/office/${officeid}`, fetchInit("GET", token)).then(check);
  }
  offices(org) {
    return fetch(`/users/api/v1/org/${org.id}/office`, fetchInit("GET", token)).then(check);
  }
  deleteOffice(org, office) {
    return fetch(`/users/api/v1/org/${org.id}/office/${office.id}`, fetchInit("DELETE", token)).then(check);
  }
  all() {
    return fetch('/users/api/v1/user', fetchInit("GET", token)).then(check);
  }
  user(usr) {
    return fetch(`/users/api/v1/user/${usr.id}`, fetchInit("GET", token)).then(check);
  }
  saveUser(usr) {
    return fetch(`/users/api/v1/user/${usr.id}`, fetchInit("PUT", token, usr)).then(check);
  }
  createUser(usr) {
    return fetch('/users/api/v1/user/', fetchInit("POST", token, usr)).then(check);
  }
  deleteUser(usr) {
    return fetch(`/users/api/v1/user/${usr.id}`, fetchInit("DELETE", token)).then(check);
  }
  saveAccounts(usr, accounts) {
    return fetch(`/users/api/v1/user/${usr.id}/accounts`, fetchInit("PUT", token, accounts))
      .then(check);
  }

  rates() {
    return fetch('/users/api/v1/rate', fetchInit("GET", token)).then(check);
  }
  rate(r) {
    return fetch(`/users/api/v1/rate/${r.id}`, fetchInit("GET", token)).then(check);
  }
  deleteRate(r) {
    return fetch(`/users/api/v1/rate/${r.id}`, fetchInit("DELETE", token)).then(check);
  }
  addRate(r) {
    return fetch(`/users/api/v1/rate/`, fetchInit("POST", token, r)).then(check);
  }
  updateRate(r) {
    return fetch(`/users/api/v1/rate/`, fetchInit("PUT", token, r)).then(check);
  }

  rateoptions() {
    return fetch('/users/api/v1/rateoption', fetchInit("GET", token)).then(check);
  }

  contacts(org) {
    return fetch(`/users/api/v1/org/contact?oid=${org.id}`, fetchInit("GET", token)).then(check);
  }
  contact(id) {
    return fetch(`/users/api/v1/org/contact/${id}`, fetchInit("GET", token)).then(check);
  }
  saveContact(contact) {
    return fetch(`/users/api/v1/org/contact`, fetchInit("PUT", token, contact)).then(check);
  }
  createContact(contact) {
    return fetch(`/users/api/v1/org/contact`, fetchInit("POST", token, contact)).then(check);
  }
  deleteContact(c) {
    return fetch(`/users/api/v1/org/contact/${c.id}`, fetchInit("DELETE", token)).then(check);
  }

  activations(orgid, imei, skip, limit) {
    let s = "limit=" + limit + "&skip=" + skip
    return fetch(`/users/api/v1/org/${orgid}/outbound/activations?imei=${imei}&${s}`, fetchInit("GET", token)).then(check);
  }

  appTokens(uid) {
    return fetch(`/users/api/v1/apptokens?userid=${uid}`, fetchInit("GET", token)).then(check);
  }

  createAppToken(tok) {
    return fetch(`/users/api/v1/apptokens`, fetchInit("POST", token, tok)).then(check);
  }

  revokeAppToken(tok) {
    return fetch(`/users/api/v1/apptokens/${tok.id}`, fetchInit("DELETE", token)).then(check);
  }

  userlogs({ oid, level, type }) {
    const l = level || "";
    const t = type || "";
    return fetch(`/users/api/v1/logs/${oid}?level=${l}&type=${t}`, fetchInit("GET", token)).then(check);
  }
}

const User = new UserApi();

class ProtegearApi {
  backlog(from, until, imei) {
    let f = "";
    if (from) f = encodeURIComponent(from.toISOString());
    let t = "";
    if (until) t = encodeURIComponent(until.toISOString());
    return fetch("/protegear/api/v1/event?imei=" + imei + "&from=" + f + "&until=" + t, fetchInit("GET", token)).then(check);
  }

  deviceevents(imei, frm, until, skip, limit, oid) {
    let f = "";
    if (frm) f = encodeURIComponent(frm.toISOString());
    let t = "";
    if (until) t = encodeURIComponent(until.toISOString());
    if (!limit) limit = 0;
    if (!skip) skip = 0;
    if (!oid) oid = "";
    let pars = "?from=" + f + "&until=" + t + "&limit=" + limit + "&skip=" + skip + "&oid=" + oid;
    let uri = encodeURI("/protegear/api/v1/device/" + imei + "/events");
    return fetch(uri + pars, fetchInit("GET", token)).then(check);
  }

  events(frm, until) {
    let f = "";
    if (frm) f = encodeURIComponent(asUTC(frm).toISOString());
    let t = "";
    if (until) t = encodeURIComponent(asUTC(until).toISOString());
    let lastid = "";
    let num = 20;
    return fetch(`/protegear/api/v1/event?from=${f}&until=${t}`, fetchInit("GET", token)).then(check);
  }
  positionAt(imei, ts, org) {
    let tss = encodeURIComponent(ts.toISOString())
    return fetch(`/protegear/api/v1/device/positionAt?id=${imei}&ts=${tss}&owner=${org}`, fetchInit("GET", token)).then(check);
  }
  alldevices() {
    return fetch(`/protegear/api/v1/device`, fetchInit("GET", token)).then(check).then(_devicesTS);
  }
  devices(org) {
    return fetch(`/protegear/api/v1/device?org=${org.id}`, fetchInit("GET", token)).then(check).then(_devicesTS);
  }
  activateDevice(dev, st) {
    return fetch(`/protegear/api/v1/device/${dev.id}/activate/${st}`, fetchInit("POST", token)).then(check).then(_deviceTS);
  }
  trips(org) {
    if (org)
      return fetch(`/tripshare/api/v1/trip?org=${org.id}`, fetchInit("GET", token)).then(check);
    return fetch('/tripshare/api/v1/trip', fetchInit("GET", token)).then(check);
  }
  tripshareVersionInfo() {
    return fetch(`/tripshare/api/v1/versionInfo`, fetchInit("GET", "")).then(check)
  }
  alltrips(org) {
    return fetch('/tripshare/api/v1/trip/all', fetchInit("GET", token)).then(check);
  }

  createTrip(trip) {
    return fetch('/tripshare/api/v1/trip', fetchInit("POST", token, trip)).then(check);
  }

  throttleTrip({ trip, value }) {
    return fetch('/tripshare/api/v1/trip/' + trip.id + "/throttle", fetchInit("POST", token, { delaySecs: value })).then(check);
  }


  loadTrip(trip) {
    return fetch(`/tripshare/api/v1/trip/${trip.id}`, fetchInit("GET", token)).then(check);
  }

  deleteTrip(trip) {
    return fetch(`/tripshare/api/v1/trip/${trip.id}`, fetchInit("DELETE", token)).then(check);
  }

  tripshareOrgSettings(settings) {
    return fetch("/tripshare/api/v1/settings/org", fetchInit("POST", token, settings)).then(check)
  }

  loadSettings(oid) {
    return fetch(`/protegear/api/v1/settings?oid=${oid}`, fetchInit("GET", token)).then(check);
  }

  saveSettings(settings) {
    return fetch(`/protegear/api/v1/settings`, fetchInit("POST", token, settings)).then(check);
  }

  createDevice(device) {
    return fetch(`/protegear/api/v1/device/${device.id}`, fetchInit("PUT", token, device)).then(check).then(_deviceTS);
  }
  deleteDevice(device) {
    return fetch(`/protegear/api/v1/device/${device.id}`, fetchInit("DELETE", token)).then(check).then(_deviceTS);
  }
  saveDevice(device) {
    return fetch(`/protegear/api/v1/device/${device.id}`, fetchInit("PUT", token, device)).then(check).then(_deviceTS);
  }
  updateDevice(device) {
    let data = {
      name: device.name,
      comment: device.comment,
      rescueService: device.configuration.rescueService,
      gsm: device.configuration.gsm,
      satellite: device.configuration.satellite,
      status: device.status,
      rescueType: device.configuration.rescueType
    }
    return fetch(`/protegear/api/v1/device/${device.id}/update`, fetchInit("POST", token, data)).then(check).then(_deviceTS);
  }
  loadDevice(device) {
    return fetch(`/protegear/api/v1/device/${device.id}`, fetchInit("GET", token)).then(check).then(_deviceTS);
  }
  allDevices() {
    return fetch('/protegear/api/v1/device', fetchInit("GET", token)).then(check).then(_devicesTS);
  }
  deviceTypes() {
    return fetch(`/protegear/api/v1/device/types`, fetchInit("GET", token)).then(check);
  }

  createEventHandler(eh) {
    return fetch(`/protegear/api/v1/eventhandler`, fetchInit("POST", token, eh)).then(check);
  }
  updateEventHandler(eh) {
    return fetch(`/protegear/api/v1/eventhandler`, fetchInit("PUT", token, eh)).then(check);
  }
  getEventHandler(id) {
    return fetch(`/protegear/api/v1/eventhandler/${id}`, fetchInit("GET", token)).then(check);
  }
  getAllEventHandlers(owner) {
    return fetch(`/protegear/api/v1/eventhandler?owner=${owner.id}`, fetchInit("GET", token)).then(check);
  }
  deleteEventHandler(id) {
    return fetch(`/protegear/api/v1/eventhandler/${id}`, fetchInit("DELETE", token)).then(check);
  }

  testMail(rcpts) {
    return fetch(`/protegear/api/v1/test/mail`, fetchInit("POST", token, rcpts)).then(check);
  }
  testSMS(rcpts) {
    return fetch(`/protegear/api/v1/test/sms`, fetchInit("POST", token, rcpts)).then(check);
  }
  testTelegram(chatid) {
    return fetch(`/protegear/api/v1/test/telegram`, fetchInit("POST", token, chatid)).then(check);
  }
  testWebhook(hookparams) {
    return fetch(`/protegear/api/v1/test/webhook`, fetchInit("POST", token, hookparams)).then(check);
  }
  testSlack(hookparams) {
    return fetch(`/protegear/api/v1/test/slack`, fetchInit("POST", token, hookparams)).then(check);
  }
  sendHook(hookid, tok, from, end, batchlimit, simulate) {
    return fetch(`/protegear/api/v1/send/webhook/${hookid}`, fetchInit("POST", token, { from, end, batchlimit, simulate })).then(check);
  }

  sendDeviceNotification(imei, n) {
    return fetch(`/protegear/api/v1/device/${imei}/sendNotification`, fetchInit("POST", token, n)).then(check);
  }
}

const Protegear = new ProtegearApi();

class TranslationApi {
  loadTranslation(appl) {
    return fetch(`/translation/api/v1/${appl}`, fetchInit("GET", token)).then(check);
  }
  putTranslation(appl, data) {
    return fetch(`/translation/api/v1/${appl}/csv`, fetchInit("PUT", token, data, "text/plain")).then(check);
  }
}


const Translation = new TranslationApi();


class CoreApi {
  allChanges(human, from, until) {
    let f = "";
    if (from) f = encodeURIComponent(from.toISOString());
    let t = "";
    if (until) t = encodeURIComponent(until.toISOString());

    return fetch(`/core/api/v1/change?human=${human}&from=${f}&until=${t}`, fetchInit("GET", token)).then(check);
  }
  appChanges(appid, from, until) {
    let f = "";
    if (from) f = encodeURIComponent(from.toISOString());
    let t = "";
    if (until) t = encodeURIComponent(until.toISOString());

    return fetch(`/core/api/v1/change/app/${appid}?from=${f}&until=${t}`, fetchInit("GET", token)).then(check);
  }
  userChanges(usrid, from, until) {
    let f = "";
    if (from) f = encodeURIComponent(from.toISOString());
    let t = "";
    if (until) t = encodeURIComponent(until.toISOString());

    return fetch(`/core/api/v1/change/user/${usrid}?from=${f}&until=${t}`, fetchInit("GET", token)).then(check);
  }
  entityChanges(eid, from, until) {
    let f = "";
    if (from) f = encodeURIComponent(from.toISOString());
    let t = "";
    if (until) t = encodeURIComponent(until.toISOString());

    return fetch(`/core/api/v1/change/entity/${eid}?from=${f}&until=${t}`, fetchInit("GET", token)).then(check);
  }

}

const Core = new CoreApi();

class CebraApi {
  isActive() {
    return true;
  }
  allProviders(orgid, onlyactive) {
    orgid = orgid || "";
    onlyactive = onlyactive ? true : false;
    return fetch(`/cebra/api/v1/provider?orgid=${orgid}&onlyactive=${onlyactive}`, fetchInit("GET", token)).then(check);
  }
  provider(prov) {
    return fetch(`/cebra/api/v1/provider/${prov.id}`, fetchInit("GET", token)).then(check).then(_providerTS);
  }
  providerAccess(prov) {
    return fetch(`/cebra/api/v1/provider/${prov.id}/access`, fetchInit("GET", token)).then(check);
  }
  saveProviderAccess(prov, provs) {
    return fetch(`/cebra/api/v1/provider/access/${prov.id}`, fetchInit("PUT", token, provs)).then(check);
  }
  allowOrg(prov, org) {
    return fetch(`/cebra/api/v1/provider/${prov.id}/orgaccess/${org.id}`, fetchInit("PUT", token)).then(check);
  }
  denyOrg(prov, org) {
    return fetch(`/cebra/api/v1/provider/${prov.id}/orgaccess/${org.id}`, fetchInit("DELETE", token)).then(check);
  }
  allowUser(prov, user) {
    return fetch(`/cebra/api/v1/provider/${prov.id}/useraccess/${user.id}`, fetchInit("PUT", token)).then(check);
  }
  denyUser(prov, user) {
    return fetch(`/cebra/api/v1/provider/${prov.id}/useraccess/${user.id}`, fetchInit("DELETE", token)).then(check);
  }
  createProvider(office) {
    return fetch(`/cebra/api/v1/provider/${office.id}`, fetchInit("POST", token)).then(check);
  }
  loadProvider(prov) {
    return fetch(`/cebra/api/v1/provider/${prov.id}`, fetchInit("GET", token)).then(check).then(_providerTS);
  }
  saveProvider(prov) {
    return fetch(`/cebra/api/v1/provider/${prov.id}`, fetchInit("PUT", token, prov)).then(check);
  }
  deleteProvider(prov) {
    return fetch(`/cebra/api/v1/provider/${prov.id}`, fetchInit("DELETE", token, prov)).then(check);
  }
  getPossiblePartners(provider, userdata) {
    if (userdata.me.manager || (provider.orgid == userdata.me.organization)) {
      return this.providerAccess(provider).then((acc) => {
        acc = acc.filter(o => o.partner).map(p => p.partner);
        acc.push(provider.organization);
        if (provider.organization.id != userdata.org.id)
          acc.push(userdata.org);
        let p = acc.sort((p1, p2) => p1.name.localeCompare(p2.name));
        return Promise.resolve(p);
      });
    } else {
      return Promise.resolve([userdata.org]);
    }
  }
  // partners
  allPartners(org) {
    return fetch(`/cebra/api/v1/partner/${org.id}`, fetchInit("GET", token)).then(check);
  }
  updatePartners(org) {
    return fetch(`/cebra/api/v1/partner/`, fetchInit("PUT", token, org)).then(check);
  }
  addPartnerOrg(org, partner) {
    return fetch(`/cebra/api/v1/partner/${org.id}/org/${partner.id}`, fetchInit("PUT", token)).then(check);
  }
  removePartnerOrg(org, partner) {
    return fetch(`/cebra/api/v1/partner/${org.id}/org/${partner.id}`, fetchInit("DELETE", token)).then(check);
  }
  addUserOrg(org, partner) {
    return fetch(`/cebra/api/v1/partner/${org.id}/user/${partner.id}`, fetchInit("PUT", token)).then(check);
  }
  removeUserOrg(org, partner) {
    return fetch(`/cebra/api/v1/partner/${org.id}/user/${partner.id}`, fetchInit("DELETE", token)).then(check);
  }
  allOrgProviderAccess(org) {
    return fetch(`/cebra/api/v1/partner/${org.id}/allProvidersAccess`, fetchInit("GET", token)).then(check);
  }

  // categories
  allCategories() {
    return fetch(`/cebra/api/v1/category`, fetchInit("GET", token)).then(check).then(cats => {
      return Promise.resolve(cats.sort((c1, c2) => c1.sortindex - c2.sortindex));
    });
  }
  allEnabledCategories() {
    return fetch(`/cebra/api/v1/category`, fetchInit("GET", token)).then(check).then(cats => {
      let c = cats.filter(c => c.enabled).sort((c1, c2) => c1.sortindex - c2.sortindex)
      return Promise.resolve(c);
    });
  }
  updateCategories(cats) {
    return fetch(`/cebra/api/v1/category/update`, fetchInit("PUT", token, cats)).then(check);
  }
  createCategory(cat) {
    return fetch(`/cebra/api/v1/category`, fetchInit("POST", token, cat)).then(check);
  }
  deleteCategory(cat) {
    return fetch(`/cebra/api/v1/category/${cat.id}`, fetchInit("DELETE", token)).then(check);
  }
  // rates
  allRates(sp) {
    return fetch(`/cebra/api/v1/rate/all/${sp.id}`, fetchInit("GET", token)).then(check).then(_rateTS);
  }
  allOrgRates(org) {
    return fetch(`/cebra/api/v1/rate/org/${org.id}`, fetchInit("GET", token)).then(check).then(_rateTS);
  }
  loadRate(rate) {
    return fetch(`/cebra/api/v1/rate/${rate.id}`, fetchInit("GET", token)).then(check);
  }
  createRate(rate) {
    return fetch(`/cebra/api/v1/rate`, fetchInit("POST", token, rate)).then(check);
  }
  deleteRate(rate) {
    return fetch(`/cebra/api/v1/rate/${rate.id}`, fetchInit("DELETE", token)).then(check);
  }
  // services


  allOrgServices(org, name, from, to) {
    let f = from ? asUTC(from).toISOString() : "";
    let t = to ? asUTC(to).toISOString() : "";
    name = name || "";
    return fetch(`/cebra/api/v1/service/org/${org.id}?name=${name}&from=${f}&to=${t}`, fetchInit("GET", token)).then(check).then(srvs => {
      return Promise.all(srvs.map(_serviceTS));
    });
  }
  allProviderServices(provider, from, to) {
    let f = from ? asUTC(from).toISOString() : "";
    let t = to ? asUTC(to).toISOString() : "";
    return fetch(`/cebra/api/v1/service/services/${provider.id}?from=${f}&to=${t}`, fetchInit("GET", token)).then(check).then(srvs => {
      return Promise.all(srvs.map(_serviceTS));
    });
  }
  availableBetween(org, provid, from, to, ignorebookingid, partial) {
    let f = asUTC(from).toISOString();
    let t = asUTC(to).toISOString();
    let ignore = "";
    let part = "false";
    if (ignorebookingid) ignore = ignorebookingid;
    if (partial) part = "true";
    return fetch(`/cebra/api/v1/service/${org.id}/availableBetween?providerid=${provid}&from=${f}&to=${t}&ignorebookingid=${ignore}&partial=${part}`, fetchInit("GET", token)).then(check).then(_servicesTS);
  }
  loadService(sv) {
    return fetch(`/cebra/api/v1/service/${sv.id}`, fetchInit("GET", token)).then(check).then(_serviceTS);
  }
  loadServiceFull(sv) {
    return fetch(`/cebra/api/v1/service/${sv.id}/full`, fetchInit("GET", token)).then(check).then(_servicesTS);
  }
  createService(sv) {
    return fetch(`/cebra/api/v1/service`, fetchInit("POST", token, sv)).then(check).then(_serviceTS);
  }
  copyService(sid, spids) {
    let toids = spids.join("&toid=");
    return fetch(`/cebra/api/v1/service/copy/${sid}?toid=${toids}`, fetchInit("POST", token)).then(check).then(_serviceTS);
  }
  updateService(sv) {
    return fetch(`/cebra/api/v1/service`, fetchInit("PUT", token, sv)).then(check).then(_serviceTS);
  }
  deleteService(sv) {
    return fetch(`/cebra/api/v1/service/${sv.id}`, fetchInit("DELETE", token)).then(check).then(_serviceTS);
  }
  bookingsAfter(sv, from) {
    let f = asUTC(from).toISOString();
    return fetch(`/cebra/api/v1/service/${sv.id}/bookingsAfter?from=${f}`, fetchInit("GET", token)).then(check).then(_bookingsTS);
  }

  // bookings
  createBooking(b) {
    return fetch(`/cebra/api/v1/booking`, fetchInit("POST", token, b))
      .then(check)
      .then(_bookingTS);
  }
  updateBooking(b) {
    return fetch(`/cebra/api/v1/booking`, fetchInit("PUT", token, b))
      .then(check)
      .then(_bookingTS);
  }
  loadBooking(bid) {
    return fetch(`/cebra/api/v1/booking/${bid}`, fetchInit("GET", token))
      .then(check)
      .then(_bookingTS);
  }
  cancelBooking(bid) {
    return fetch(`/cebra/api/v1/booking/${bid}/cancel`, fetchInit("POST", token))
      .then(check)
      .then(_bookingTS);
  }
  allBookings(from, to, state, provider) {
    return fetch(`/cebra/api/v1/booking?from=${asUTC(from).toISOString()}&to=${asUTC(to).toISOString()}&status=${state}&providerid=${provider.id}`, fetchInit("GET", token))
      .then(check)
      .then(_bookingsTS);
  }
  changedBookings(from, to, provider) {
    return fetch(`/cebra/api/v1/booking/changes?from=${asUTC(from).toISOString()}&to=${asUTC(to).toISOString()}&providerid=${provider.id}`, fetchInit("GET", token))
      .then(check)
      .then(_bookingsTS);
  }
  arrivals(from, to, provider) {
    return fetch(`/cebra/api/v1/booking/${provider.id}/arrivals?from=${asUTC(from).toISOString()}&to=${asUTC(to).toISOString()}`, fetchInit("GET", token))
      .then(check)
      .then(_bookingsTS);
  }
  departures(from, to, provider) {
    return fetch(`/cebra/api/v1/booking/${provider.id}/departures?from=${asUTC(from).toISOString()}&to=${asUTC(to).toISOString()}`, fetchInit("GET", token))
      .then(check)
      .then(_bookingsTS);
  }
  findBookings(providerid, search, name, email, comment, externalid, onlyok, exact) {
    return fetch(`/cebra/api/v1/booking/find?providerid=${providerid}&search=${search}&comment=${comment}&name=${name}&email=${email}&externalid=${externalid}&onlyok=${onlyok}&exact=${exact}`, fetchInit("GET", token))
      .then(check)
      .then(_bookingsTS);
  }

  // payments
  findPayments(orgid, from, to, status, bookingstate) {
    return fetch(`/cebra/api/v1/payment/${orgid}/search?from=${asUTC(from).toISOString()}&to=${asUTC(to).toISOString()}&status=${status}&bookingstate=${bookingstate}`, fetchInit("GET", token))
      .then(check)
      .then(_paymentsTS);
  }
  // all payments
  allPayments(from, to, status, bookingstate) {
    return fetch(`/cebra/api/v1/payment?from=${asUTC(from).toISOString()}&to=${asUTC(to).toISOString()}&status=${status}&bookingstate=${bookingstate}`, fetchInit("GET", token))
      .then(check)
      .then(_paymentsTS);
  }


  // commissioning/billing
  loadCommission(id) {
    return fetch(`/cebra/api/v1/commissioning/${id}`, fetchInit("GET", token)).then(check)
  }
  createCommission(id) {
    return fetch(`/cebra/api/v1/commissioning/${id}`, fetchInit("POST", token)).then(check)
  }
  updateCommission(comm) {
    return fetch(`/cebra/api/v1/commissioning/${comm.id}`, fetchInit("PUT", token, comm)).then(check)
  }
  htmlBilling(org, frm, to, providerid, categoryid, ownerid, cancelled, zeroprice, bounds, includeowner, includeprovider, includecategory) {
    let f = "";
    if (frm) f = encodeURIComponent(asUTC(frm).toISOString());
    let t = "";
    if (to) t = encodeURIComponent(asUTC(to).toISOString());
    let canc = "";
    if (cancelled) canc = "true";
    let zero = "";
    if (zeroprice) zero = "true";

    return fetch(`/cebra/api/v1/billing/${org.id}/html?from=${f}&to=${t}&cancelled=${canc}&zeropriced=${zero}&providerid=${providerid}&ownerid=${ownerid}&bounds=${bounds}&categoryid=${categoryid}&includeowner=${includeowner}&includeprovider=${includeprovider}&includecategory=${includecategory}`, fetchInit("GET", token)).then(check)
  }

  // config
  loadConfiguration() {
    return fetch(`/cebra/api/v1/configuration`, fetchInit("GET", token)).then(check)
  }
  saveConfiguration(cfg) {
    return fetch(`/cebra/api/v1/configuration`, fetchInit("PUT", token, cfg)).then(check)
  }
  loadSettings(oid) {
    return fetch(`/cebra/api/v1/configuration/${oid}/settings`, fetchInit("GET", token)).then(check)
  }
  saveSettings(oid, settings) {
    return fetch(`/cebra/api/v1/configuration/${oid}/settings`, fetchInit("PUT", token, settings)).then(check)
  }
  loadAllCebraOrgs() {
    return fetch(`/cebra/api/v1/configuration/orgs`, fetchInit("GET", token)).then(check)
  }

  versionInfo() {
    return fetch(`/cebra/api/v1/versionInfo`, fetchInit("GET", "")).then(check)
  }

  // help

  feedback(subject, message) {
    let b = { subject, message };
    return fetch(`/cebra/api/v1/help/feedback`, fetchInit("POST", token, b)).then(check)
  }

  // validation
  days(from, to) {
    var utc1 = Date.UTC(from.getFullYear(), from.getMonth(), from.getDate());
    var utc2 = Date.UTC(to.getFullYear(), to.getMonth(), to.getDate());

    return Math.round((utc2 - utc1) / (1000 * 60 * 60 * 24));
  }

  bookingRange(days) {
    return days >= 0 && days <= 28;
  }

  isServiceAvailable(srv, from, to) {
    let f = isBetween(srv.validfrom, from, srv.validto);
    if (to) f = f && isBetween(srv.validfrom, to, srv.validto);
    return f;
  }

  isRateAvailable(rate, from, to) {
    return isBetween(rate.validfrom, from, rate.validto); // && isBetween(rate.validfrom, to, rate.validto);
  }

  isProviderManager(provider, user) {
    return provider && (user.manager || (user.orgmanager && user.organization == provider.orgid));
  }

  isProviderUser(provider, user) {
    return provider && (user.manager || user.organization == provider.orgid);
  }
  isInOpening(provider, d) {
    // assume no opening times -> always open
    if (!provider.openingtimes || !provider.openingtimes.length) return true;

    for (var ot of provider.openingtimes) {
      if (isBetween(ot.from, d, ot.to)) {
        return true;
      }
    }
    return false;
  }
}

const Cebra = new CebraApi();


class CebraDirectApi {
  // payment settings (only admin)
  loadSettings() {
    return fetch(`/cebradirect/api/v1/settings`, fetchInit("GET", token)).then(check);
  }
  saveSettings(s) {
    return fetch(`/cebradirect/api/v1/settings`, fetchInit("PUT", token, s)).then(check);
  }

  // directbooking
  loadDirectbooking(org) {
    return fetch(`/cebradirect/api/v1/${org.id}`, fetchInit("GET", token)).then(check).then(_couponTS);
  }
  updateDirectbooking(org, db) {
    return fetch(`/cebradirect/api/v1/${org.id}`, fetchInit("PUT", token, db)).then(check).then(_couponTS);
  }
  loadConfig(dbid) {
    return fetch(`/cebradirect/api/v1/${dbid}/config`, fetchInit("GET", token)).then(check);
  }
  allCategories() {
    return fetch(`/cebradirect/api/v1/categories`, fetchInit("GET", token)).then(check).then(cats => {
      return Promise.resolve(cats.sort((c1, c2) => c1.sortindex - c2.sortindex));
    });
  }
  loadDestinations(dbid) {
    return fetch(`/cebradirect/api/v1/${dbid}/destinations`, fetchInit("GET", token))
      .then(check)
      .then(_providersTS);
  }
  loadServices(dbid, orgid, provid, from, to) {
    let f = asUTC(from).toISOString();
    let t = asUTC(to).toISOString();

    return fetch(`/cebradirect/api/v1/${dbid}/${orgid}/${provid}/availableServices?from=${f}&to=${t}`, fetchInit("GET", token))
      .then(check)
      .then(_servicesTS);
  }

  checkCoupon(dbid, coupon) {
    return fetch(`/cebradirect/api/v1/${dbid}/coupon/${coupon}`, fetchInit("POST", token)).then(check);
  }

  checkBank(dbid, iban, bic) {
    return fetch(`/cebradirect/api/v1/${dbid}/${bic}/${iban}/check`, fetchInit("GET", token)).then(check);
  }

  putTransaction(dbid, tx) {
    return fetch(`/cebradirect/api/v1/${dbid}/transaction`, fetchInit("PUT", token, tx)).then(check);
  }
  paygateResult(dbid, len, data) {
    return fetch(`/cebradirect/api/v1/${dbid}/paygateResult/${len}/${data}`, fetchInit("GET", token)).then(check);
  }
  paypalExecute(dbid, payerid, paymentid) {
    return fetch(`/cebradirect/api/v1/${dbid}/paypalExecutePayment?payerid=${payerid}&paymentid=${paymentid}`, fetchInit("POST", token)).then(check);
  }
}


const CebraDirect = new CebraDirectApi();

class ManagerApi {
  // payment settings (only admin)
  configSettings() {
    return fetch(`/manager/api/v1/config`, fetchInit("GET", token)).then(check);
  }
  saveConfigSettings(s) {
    return fetch(`/manager/api/v1/config`, fetchInit("PUT", token, s)).then(check);
  }
}

class SafeguardApi {
  addTour(tour) {
    return fetch(`/safeguard/api/v1/tour`, fetchInit("POST", token, tour)).then(check);
  }
  delTour(id) {
    return fetch(`/safeguard/api/v1/tour/${id}`, fetchInit("DELETE", token)).then(check);
  }
  updateTour(tour) {
    return fetch(`/safeguard/api/v1/tour`, fetchInit("PUT", token, tour)).then(check);
  }
  allTours(orgid, visibility, index, limit) {
    return fetch(`/safeguard/api/v1/tour?index=${index}&visibility=${visibility}&limit=${limit}&orgid=${orgid}`, fetchInit("GET", token)).then(check);
  }
  tour(id) {
    return fetch(`/safeguard/api/v1/tour/${id}`, fetchInit("GET", token)).then(check);
  }
  addFleet(fleet) {
    return fetch(`/safeguard/api/v1/fleet`, fetchInit("POST", token, fleet)).then(check);
  }
  updateFleet(fleet) {
    return fetch(`/safeguard/api/v1/fleet`, fetchInit("PUT", token, fleet)).then(check);
  }
  allFleets(orgid, index, limit) {
    return fetch(`/safeguard/api/v1/fleet?index=${index}&limit=${limit}&orgid=${orgid}`, fetchInit("GET", token)).then(check);
  }
  fleet(id) {
    return fetch(`/safeguard/api/v1/fleet/${id}`, fetchInit("GET", token)).then(check);
  }
  delFleet(id) {
    return fetch(`/safeguard/api/v1/fleet/${id}`, fetchInit("DELETE", token)).then(check);
  }
  availableDevices(orgid) {
    return fetch(`/safeguard/api/v1/fleet/availableDevices?orgid=${orgid}`, fetchInit("GET", token)).then(check);
  }
  addTrace(tr) {
    return fetch(`/safeguard/api/v1/trace`, fetchInit("POST", token, tr)).then(check);
  }
  delTrace(tr) {
    return fetch(`/safeguard/api/v1/trace/${tr.id}`, fetchInit("DELETE", token)).then(check);
  }
  updateTrace(tr) {
    return fetch(`/safeguard/api/v1/trace`, fetchInit("PUT", token, tr)).then(check);
  }
  updateTraceFenceData(trid, fence) {
    return fetch(`/safeguard/api/v1/trace/${trid}/fencedata`, fetchInit("PUT", token, fence)).then(check);
  }
  allTraces(orgid, index, limit) {
    return fetch(`/safeguard/api/v1/trace?index=${index}&limit=${limit}&orgid=${orgid}`, fetchInit("GET", token)).then(check);
  }
  trace(id) {
    return fetch(`/safeguard/api/v1/trace/${id}`, fetchInit("GET", token)).then(check);
  }
  tracestate(id) {
    return fetch(`/safeguard/api/v1/trace/${id}/state`, fetchInit("GET", token)).then(check);
  }
  stateSummary(org) {
    return fetch(`/safeguard/api/v1/trace/${org.id}/statesummary`, fetchInit("GET", token)).then(check);
  }
  send(id, msg, imei, channel, tos) {
    let dests = tos.join("&to=");
    let mess = encodeURIComponent(msg);
    return fetch(`/safeguard/api/v1/trace/${id}/send?imei=${imei}&message=${mess}&channel=${channel}&to=${dests}`, fetchInit("GET", token)).then(check);
  }
  addProfile(pr) {
    return fetch(`/safeguard/api/v1/profile`, fetchInit("POST", token, pr)).then(check);
  }
  delProfile(id) {
    return fetch(`/safeguard/api/v1/profile/${id}`, fetchInit("DELETE", token)).then(check);
  }
  updateProfile(pr) {
    return fetch(`/safeguard/api/v1/profile`, fetchInit("PUT", token, pr)).then(check);
  }
  allProfiles(orgid, index, limit) {
    return fetch(`/safeguard/api/v1/profile?index=${index}&limit=${limit}&orgid=${orgid}`, fetchInit("GET", token)).then(check);
  }
  allProfileTemplates() {
    return fetch(`/safeguard/api/v1/profile?index=0&limit=20&orgid=DEFAULT&templates=true`, fetchInit("GET", token)).then(check);
  }
  profile(id) {
    return fetch(`/safeguard/api/v1/profile/${id}`, fetchInit("GET", token)).then(check);
  }

  addEscalation(e) {
    return fetch(`/safeguard/api/v1/escalation`, fetchInit("POST", token, e)).then(check);
  }
  delEscalation(id) {
    return fetch(`/safeguard/api/v1/escalation/${id}`, fetchInit("DELETE", token)).then(check);
  }
  addEscalationComment(e, comment, close, offset) {
    let d = { comment, close, offset }
    return fetch(`/safeguard/api/v1/escalation/${e.id}/comment`, fetchInit("POST", token, d)).then(check);
  }
  allEscalations(orgid, index, limit) {
    return fetch(`/safeguard/api/v1/escalation?index=${index}&limit=${limit}&orgid=${orgid}`, fetchInit("GET", token)).then(check);
  }
  escalation(id) {
    return fetch(`/safeguard/api/v1/escalation/${id}`, fetchInit("GET", token)).then(check);
  }

  addNotification(n) {
    return fetch(`/safeguard/api/v1/notification`, fetchInit("POST", token, n)).then(check);
  }
  delNotification(id) {
    return fetch(`/safeguard/api/v1/notification/${id}`, fetchInit("DELETE", token)).then(check);
  }
  updateNotification(n) {
    return fetch(`/safeguard/api/v1/notification`, fetchInit("PUT", token, n)).then(check);
  }
  allNotifications(orgid, index, limit) {
    return fetch(`/safeguard/api/v1/notification?index=${index}&limit=${limit}&orgid=${orgid}`, fetchInit("GET", token)).then(check);
  }
  notification(id) {
    return fetch(`/safeguard/api/v1/notification/${id}`, fetchInit("GET", token)).then(check);
  }
  testMail(rcpts) {
    return fetch(`/protegear/api/v1/test/mail`, fetchInit("POST", token, rcpts)).then(check);
  }
  testSMS(rcpts) {
    return fetch(`/protegear/api/v1/test/sms`, fetchInit("POST", token, rcpts)).then(check);
  }
  testHook(n) {
    return fetch(`/safeguard/api/v1/notification/test/hook`, fetchInit("POST", token, n)).then(check);
  }
  testSlack(n) {
    return fetch(`/safeguard/api/v1/notification/test/slack`, fetchInit("POST", token, n)).then(check);
  }
  testTelegram(rcpts) {
    return fetch(`/protegear/api/v1/test/telegram`, fetchInit("POST", token, rcpts)).then(check);
  }

  getSettings(oid) {
    return fetch(`/safeguard/api/v1/settings?oid=${oid}`, fetchInit("GET", token)).then(check);
  }
  saveSettings(oid, parms) {
    let pars = ["oid=" + oid];
    if ("information" in parms)
      pars.push("information=" + parms.information);
    if ("allowdevicedeactivation" in parms)
      pars.push("allowdevicedeactivation=" + parms.allowdevicedeactivation);
    if ("enablestatusemail" in parms)
      pars.push("enablestatusemail=" + parms.enablestatusemail);
    if ("statusemailtrigger" in parms)
      pars.push("statusemailtrigger=" + parms.statusemailtrigger);
    if (parms.statusemailaddresses) {
      for (let em of parms.statusemailaddresses) {
        pars.push("statusemailaddresses=" + encodeURIComponent(em))
      }
    }

    let ps = pars.join("&");
    return fetch(`/safeguard/api/v1/settings`, fetchInit("POST", token, ps, "application/x-www-form-urlencoded")).then(check);
  }

  devicecontacts(org) {
    return fetch(`/safeguard/api/v1/devicecontact?orgid=${org.id}`, fetchInit("GET", token)).then(check);
  }
  devicecontact(id) {
    return fetch(`/safeguard/api/v1/devicecontact/${id}`, fetchInit("GET", token)).then(check);
  }
  saveDevicecontact(contact) {
    return fetch(`/safeguard/api/v1/devicecontact`, fetchInit("PUT", token, contact)).then(check);
  }
  createDevicecontact(contact) {
    return fetch(`/safeguard/api/v1/devicecontact`, fetchInit("POST", token, contact)).then(check);
  }
  deleteDevicecontact(c) {
    return fetch(`/safeguard/api/v1/devicecontact/${c.id}`, fetchInit("DELETE", token)).then(check);
  }

  availableRescueOrgs() {
    return fetch(`/safeguard/api/v1/rescueorgs`, fetchInit("GET", token)).then(check);
  }

  // ### client side logic ...

  toBounds(pts) {
    return [pts.map(Safeguard.toLatLng)];
  }
  toLatLng(pt) {
    if (pt.lng) return pt;
    return { lat: pt.latitude, lng: pt.longitude };
  }

  shapeOptions(layertype, high) {
    let shapecolor = shapeColors[layertype];
    if (high)
      shapecolor = shapeColorsHigh[layertype];

    let shopts = {
      color: shapecolor,
      weight: 2,
      clickable: true,
    };
    return shopts;
  }

  createPathElement(layertype, tpname, ptype, element, high) {
    let shopts = this.shapeOptions(layertype, high);
    let rect = new ptype(this.toBounds(element.points), shopts);
    rect.info = {
      type: tpname,
      name: element.name,
    }
    return rect;
  }

  createCircleElement(layertype, tpname, ptype, element, high) {
    let shopts = this.shapeOptions(layertype, high);
    let p = Safeguard.toLatLng(element.center);
    shopts.radius = element.radiusmeter;
    let c = new ptype(p, shopts);
    c.info = {
      type: tpname,
      name: element.name
    }
    return c;
  }

  openEscalations(trace) {
    let esc = trace.output.escalations || [];
    return esc.filter(e => {
      let start = new Date(e.start);
      let end = new Date(e.end);
      return end.getTime() < start.getTime();
    });
  }
}

class RescueApi {
  rescue(id) {
    return fetch(`/rescue/api/v1/rescue/${id}`, fetchInit("GET", token)).then(check);
  }
  createEscalation(traceid, imei, author) {
    let d = { author, traceid, imei }
    return fetch("/rescue/api/v1/rescue/escalation/", fetchInit("POST", token, d)).then(check);
  }

  escalation(id) {
    return fetch(`/rescue/api/v1/rescue/escalation/${id}`, fetchInit("GET", token)).then(check);
  }
  deviceevents(escid, imei, frm, until, skip, limit) {
    let f = "";
    if (frm) f = encodeURIComponent(frm.toISOString());
    let t = "";
    if (until) t = encodeURIComponent(until.toISOString());
    if (!limit) limit = 100;
    if (!skip) skip = 0;
    let pars = "?from=" + f + "&until=" + t + "&limit=" + limit + "&skip=" + skip
    return fetch(`/rescue/api/v1/rescue/${escid}/${imei}/events` + pars, fetchInit("GET", token)).then(check);
  }
  escalationState(tid, imei) {
    return fetch(`/rescue/api/v1/rescue/${tid}/${imei}/escalationState`, fetchInit("GET", token)).then(check);
  }
  comment(e, comment, author, close, offset) {
    let d = { comment, author, close, offset }
    return fetch(`/rescue/api/v1/rescue/${e.id}/comment`, fetchInit("POST", token, d)).then(check);
  }
  demote(tid, imei, comment, author, offset) {
    let d = { comment, author, offset }
    return fetch(`/rescue/api/v1/rescue/${tid}/${imei}/demote`, fetchInit("POST", token, d)).then(check);
  }
}

class GlobalMailApi {
  targets(orgid) {
    let pars = "?index=0&limit=-1";
    if (orgid) {
      pars += "&orgid=" + orgid;
    }
    return fetch(`/globalmail/api/v1/target/` + pars, fetchInit("GET", token)).then(check);
  }
  target(id) {
    return fetch(`/globalmail/api/v1/target/${id}`, fetchInit("GET", token)).then(check);
  }
  addTarget(t) {
    return fetch(`/globalmail/api/v1/target/`, fetchInit("POST", token, t)).then(check);
  }
  updateTarget(t) {
    return fetch(`/globalmail/api/v1/target/`, fetchInit("PUT", token, t)).then(check);
  }
  delTarget(id) {
    return fetch(`/globalmail/api/v1/target/${id}`, fetchInit("DELETE", token)).then(check);
  }
  config() {
    return fetch(`/globalmail/api/v1/base/config`, fetchInit("GET", token)).then(check);
  }
}

const Safeguard = new SafeguardApi()
const Manager = new ManagerApi();
const Rescue = new RescueApi();
const GlobalMail = new GlobalMailApi();

export { Cebra, CebraDirect, Core, GlobalMail, Manager, Protegear, Rescue, Safeguard, Translation, User };

export const DEPRECATE_OLD_APPS = true;

export let Applications = {
  console: {
    title: "Protegear",
    url: "/console/"
  },
  tripshare: {
    title: "Tripshare",
    url: "/tripshare/",
    deprecated: DEPRECATE_OLD_APPS,
  },
  manager: {
    title: "Manager",
    url: "/manager/",
    deprecated: DEPRECATE_OLD_APPS
  },
  cebra: {
    title: "Cebra",
    url: "/cebra/"
  },
  guardian: {
    title: "SmartSafety",
    url: "/guardian/",
    deprecated: DEPRECATE_OLD_APPS,
  },
  sports: {
    title: "Sports",
    url: "/sports/",
    deprecated: DEPRECATE_OLD_APPS,
  }
};

export let CurrencySymbols = {
  USD: "\u0024",
  EUR: "\u20AC"
}

export function hasRateOption(org, srv, name) {
  let rates = org.output.rates || [];
  for (let r of rates) {
    for (let o of r.options) {
      if (o.service == srv && o.name == name) {
        return true;
      }
    }
  }
  return false;
}
export function canGlobalmail(org) {
  return hasRateOption(org, "globalmail", "application")
}
export function hasAPIAccess(org) {
  return hasRateOption(org, "protegear", "api")
}
export function canChangeLifetime(org) {
  return hasRateOption(org, "protegear", "Event Lifetime Edit")
}


class PersistentGSTorage {
  getToken() {
    return localStorage.getItem("__gst_token__");
  }
  setToken(token) {
    localStorage.setItem("__gst_token__", token);
  }
  clearToken() {
    this.setToken(null);
  }
  getUser() {
    return JSON.parse(localStorage.getItem("__gst_user__"));
  }
  setUser(usr) {
    localStorage.setItem("__gst_user__", JSON.stringify(usr));
  }
  clearUser() {
    this.setUser(null);
  }
  setSelectedOrg(org) {
    localStorage.setItem("__gst_selected_org__", JSON.stringify(org));
  }
  getSelectedOrg() {
    return JSON.parse(localStorage.getItem("__gst_selected_org__"));
  }
  clearSelectedOrg() {
    this.setSelectedOrg(null);
  }
  getAppSettings(app) {
    const usersettings = localStorage.getItem(`__gst_${app}__`)
    return usersettings ? JSON.parse(usersettings) : {};
  }
  saveAppSettings(app, k, val) {
    const settings = this.getAppSettings(app);
    settings[k] = val;
    localStorage.setItem(`__gst_${app}__`, JSON.stringify(settings));
  }
}

export function validPosition(p) {
  if (p.invalid) {
    return false;
  }
  if (p.gpsfix < 1) {
    return false;
  }
  if (p.lat == 0 && p.lng == 0) {
    return false
  }
  if (Math.abs(p.lat) > 90) {
    return false
  }
  if (Math.abs(p.lng) > 180) {
    return false
  }
  return true
}

export let GSTorage = new PersistentGSTorage()

export function isFeaturesEnabled() {
  return navigator.languages.indexOf("zu") > -1 && navigator.languages.indexOf("de-DE") > -1;;
}

export function isDevHost() {
  if (location.host.indexOf("localhost") > -1) return true;
  if (location.host.indexOf("protegear.dev") > -1) return true;
  if (location.hostname.endsWith("ts.net")) return true;
}